<template>
  <tx-dialog
    v-model="visible" :title="t('customSort.title')" width="40%" height="65%" :loading="loading" show-ok-cancel @ok="confirm"
    @closed="close" @opened="opened" @click="cancel"
  >
    <div class="w-full h-full">
      <div class="w-full my-2">
        <tx-select
          v-model="sortProperty" :label="t('customSort.selectArticleProperty')" :data="sortableProperties" value-prop="articleProperty" display-prop="displayLabel"
          clearable filterable @change="onSortPropertySelectionChange"
        />
      </div>
      <div class="w-full my-2">
        <tx-list-transfer
          v-model:selected-items="sortPropertySelectedValues"
          :available-items-label="t('customSort.available')" :selected-items-label="t('customSort.sorted')" :items="sortPropertyValues"
          item-key-prop="property" item-display-prop="title" class="max-h-96"
        />
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang='ts'>
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type Article from '@/models/article'
import { AttributeType } from '@/models/catalogAttribute'
import TxListTransfer from '@/shared/components/txListTransfer'
import TxSelect from '@/shared/components/TxSelect.vue'
import utils from '@/services/utils'

interface IProps {
  modelValue: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: false,
})

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'customSort', selectedArticleSortPropertyObject: Record<string, string[]>): void
  (e: 'update:modelValue', modelValue: boolean): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const visible = ref(false)
const loading = ref(false)
const customSortAttributesUniqueValueMap: Record<string, Array<{ title: any, typeId: AttributeType }>> = {}
const sortProperty = ref<string>('')
const sortPropertyValues = ref<any[]>([])
const sortPropertySelectedValues = ref<any[]>([])

const sortableProperties = computed(() => {
  const sortableProperties = [] as Array<{ displayLabel: string, articleProperty: string, direction?: string, typeId: AttributeType }>
  userStore.activeCatalog?.Config.ArticlesSortProperties.forEach((articleSortConfigValue) => {
    if (userStore.myAttributes && userStore.myAttributes.hasOwnProperty(articleSortConfigValue.articleProperty)) {
      const sortOption = {
        displayLabel: userStore.myAttributes[articleSortConfigValue.articleProperty].DisplayName,
        articleProperty: articleSortConfigValue.articleProperty,
        direction: articleSortConfigValue.sortDirection,
        typeId: userStore.myAttributes[articleSortConfigValue.articleProperty].AttributeType,
      }
      utils.insertSorted(sortOption, sortableProperties, (a, b) => utils.comparer(a, b, ['displayLabel']))
    }
  })
  return sortableProperties
})

onMounted(() => {
  init()
})

async function init() {
  if (userStore.activeCatalog) {
    loading.value = true
    const articles = await appConfig.DB!.articles.where('CatalogCode').equals(userStore.activeCatalog.CatalogCode).toArray()
    if (articles.length) {
      articles.forEach((article) => {
        sortableProperties.value.forEach((sortableProperty) => {
          const attributeSystemName = sortableProperty.articleProperty
          if (!customSortAttributesUniqueValueMap[attributeSystemName]) {
            customSortAttributesUniqueValueMap[attributeSystemName] = []
          }
          let articleValue = article[attributeSystemName] as any
          if (attributeSystemName === '_WholesalePrice') {
            articleValue = userStore.priceGroups.wholesale ? article._Prices[userStore.priceGroups.wholesale.Id]?.Price || 0 : 0
          }
          else if (attributeSystemName === '_RetailPrice') {
            articleValue = userStore.priceGroups.retail ? article._Prices[userStore.priceGroups.retail.Id]?.Price || 0 : 0
          }
          else if (attributeSystemName === '_OutletPrice') {
            articleValue = userStore.priceGroups.outlet ? article._Prices[userStore.priceGroups.outlet.Id]?.Price || 0 : 0
          }
          else if (sortableProperty.typeId === AttributeType.Int || sortableProperty.typeId === AttributeType.Decimal) {
            articleValue = articleValue && articleValue !== '' ? Number(articleValue) : 0
          }
          else if (sortableProperty.typeId === AttributeType.DateTime || sortableProperty.typeId === AttributeType.Date || sortableProperty.typeId === AttributeType.DateOption) {
            if (!utils.isValidStringValue(articleValue) || articleValue === 'null') {
              articleValue = '[Blank]'
            }
            else {
              articleValue = utils.formatDate(articleValue)
              articleValue = articleValue !== '' && articleValue !== 'Invalid Date' ? articleValue : '[Blank]'
            }
          }
          else {
            if (articleValue == null || articleValue.toString().trim() === '') {
              articleValue = '[Blank]'
            }
            else {
              articleValue = articleValue.toString().trim().toLowerCase()
            }
          }
          const uniqueValues = customSortAttributesUniqueValueMap[attributeSystemName]
          if (!uniqueValues.some(item => item.title === articleValue)) {
            utils.insertSorted({ title: articleValue, typeId: sortableProperty.typeId }, customSortAttributesUniqueValueMap[attributeSystemName], (a, b) => utils.comparer(a, b, ['title']))
          }
        })
      })
      loading.value = false
    }
  }
}

function onSortPropertySelectionChange(item) {
  sortPropertyValues.value = customSortAttributesUniqueValueMap[item]
  sortPropertySelectedValues.value = []
}

async function confirm() {
  loading.value = true
  const selectedArticleSortPropertyObject: Record<string, string[]> = {}
  sortPropertySelectedValues.value.forEach((itm) => {
    if (!selectedArticleSortPropertyObject[sortProperty.value]) {
      selectedArticleSortPropertyObject[sortProperty.value] = []
    }
    selectedArticleSortPropertyObject[sortProperty.value].push(itm.title)
  })
  emit('customSort', selectedArticleSortPropertyObject)
}

function cancel() {
  close()
  emit('cancel')
}

function close() {
  emit('update:modelValue', false)
}

function opened() {
  loading.value = false
}

watch(() => userStore.sortBy, (sortByN, sortByO) => {
  sortProperty.value = sortByO == null || sortByO === '_customSort' ? '' : sortByO
  if (sortProperty.value !== '') { // avoid default sort
    onSortPropertySelectionChange(sortProperty.value)
  }
})

watch(() => props.modelValue, (modelValue) => {
  visible.value = modelValue
})
</script>
