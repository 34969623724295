import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import useEventsBus from '@/shared/composables/eventBus'

// When approve,reject and confirm only state, comment, and reason are changed so updating them locally
export default function useRequestLocalDataUpdater() {
  const userStore = useUserStore()
  const { emitter } = useEventsBus()

  async function updateRequestsLocally(
    requestIds: number[],
    propertiesToUpdate: { state: number, comment: string, reason: string, updatedByUserName: string },
    notify = false,
  ) {
    const catalog = userStore.activeCatalog
    if (catalog) {
      const updatePromises = requestIds.map(async (requestId) => {
        const request = await appConfig.DB!.requests.get([catalog.CatalogCode, requestId])
        if (request) {
          // Update properties: state, comment, and reason
          return appConfig.DB!.requests.update([catalog.CatalogCode, requestId], {
            State: propertiesToUpdate.state,
            Comment: propertiesToUpdate.comment,
            Reason: propertiesToUpdate.reason,
            UpdatedByUserName: propertiesToUpdate.updatedByUserName,
            LocalUpdatedDate: new Date(),
          })
        }
      })

      await Promise.all(updatePromises)

      if (notify) {
        emitter('catalogDataUpdated', { source: 'Requests' })
      }
    }
  }

  return {
    updateRequestsLocally,
  }
}
