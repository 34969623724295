<template>
  <div class="container flex flex-col w-full h-full overflow-hidden">
    <div class="flex items-center justify-end">
      <div class="px-2 py-1 text-white border rounded bg-primary">
        {{ auditTrailHistory.length }} {{ t('general.records', auditTrailHistory.length) }}
      </div>
      <font-awesome-icon class="w-6 h-6 p-1 mx-1 cursor-pointer hover:text-primary-500" icon="fa-light fa-refresh" @click="onLoad" />
    </div>
    <div class="relative w-full overflow-y-auto flex-grow">
      <loader v-if="loading" />
      <div v-else class="flex flex-col grid-cols-18 md:grid">
        <div v-for="(trail, index) in auditTrailHistory" :key="index" class="flex md:contents">
          <div class="relative col-start-1 col-end-3 md:mx-auto">
            <div class="flex items-center justify-center w-6 h-full">
              <div class="w-1 h-full pointer-events-none bg-primary" />
            </div>
            <div class="absolute w-6 h-6 -mt-3 text-center rounded-full shadow bg-primary top-1/2">
              <i class="text-white fas fa-check-circle" />
            </div>
          </div>
          <div class="w-full col-start-3 p-4 my-4 mr-auto shadow-md col-end-18 bg-grey-light rounded-xl">
            <div>
              <ul class="mb-1 text-base">
                <li v-for="change in trail.changes" :key="change.label">
                  <span class="font-semibold">{{ change.label }}</span>
                  <span v-if="change.key !== 'created'" class="font-semibold">:</span>
                  <template v-if="change.key === '_segmentations' || change.key === '_prices' || change.key === '_requestedAttributes' || change.key === '_requestState'">
                    <div v-for="dataDisplayValue in Object.keys(change.newValue)" :key="dataDisplayValue">
                      <span v-if="dataDisplayValue !== 'attribute'" class="font-semibold">{{ dataDisplayValue }}</span>
                      <span class="px-1 mx-1 line-through break-words bg-grey">{{ change.oldValue[dataDisplayValue] }}</span>
                      <span class="px-1 mx-1 break-words bg-green-400">{{ change.newValue[dataDisplayValue] }}</span>
                    </div>
                  </template>
                  <template v-else-if="change.key !== 'created'">
                    <span class="px-1 mx-1 line-through break-words bg-grey">{{ change.oldValue.attribute }}</span>
                    <span class="px-1 mx-1 break-words bg-green-400">{{ change.newValue.attribute }}</span>
                  </template>
                </li>
              </ul>
            </div>
            <div class="flex w-full pt-2 text-sm">
              <div v-tooltip="trail.changeDate" class="w-1/2 leading-tight text-justify">
                <use-time-ago v-slot="{ timeAgo }" :time="new Date(trail.changeDate)">
                  {{ timeAgo }}
                </use-time-ago>
              </div>
              <div class="w-1/2 font-medium leading-tight text-right">
                {{ trail.changeMaker }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!auditTrailHistory.length" class="col-start-1 col-end-12">
          <p>{{ t('general.emptyList') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UseTimeAgo } from '@vueuse/components'
import { useI18n } from 'vue-i18n'
import { onMounted, ref, watch } from 'vue'
import Loader from '@/shared/components/Loader.vue'
import type MyArticle from '@/models/myArticle'
import { getArticleHistory } from '@/api/t1/article'
import { getRequestHistory } from '@/api/t1/request'
import utils from '@/services/utils'
import usePriceGroupsLabel from '@/shared/composables/priceGroupsLabel'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  article: MyArticle
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { priceGroupsLabel } = usePriceGroupsLabel()

const loading = ref(false)

interface ITrail {
  changeDate: string
  changeMaker: string
  changes: { key: string, label: string, newValue: Record<string, string>, oldValue: Record<string, string> }[]
}
const auditTrailHistory = ref<ITrail[]>([])

function onLoad() {
  auditTrailHistory.value = []
  if (userStore.activeCatalog) {
    const catalogDetails = userStore.activeCatalog.CatalogCode === props.article.CatalogCode
      ? userStore.activeCatalog
      : userStore.linkedCatalogDetails[props.article.CatalogCode]
    loading.value = true

    const promise = props.article._IsRequestArticle
      ? getRequestHistory(catalogDetails.CatalogCode, props.article.Id)
      : getArticleHistory(catalogDetails.CatalogCode, props.article.Id)

    promise.then((res) => {
      for (let i = res.data.length - 1; i >= 0; i--) {
        const history = res.data[i]
        const keys = Object.keys(history.New)
        if (keys.length) {
          const trail: ITrail = {
            changeDate: utils.formatDateTime(history.ChangeDate),
            changeMaker: history.ChangeMaker,
            changes: [],
          }
          keys.forEach((key) => {
            let propertyDisplayValue = ''
            let newValue = {}
            let oldValue = {}
            if (key === '_segmentations') {
              propertyDisplayValue = t('auditTrail.segmentations')
              Object.keys(history.New[key]).forEach((segmentationId) => {
                if (utils.isDefined(catalogDetails._IndexedCatalogSegmentation[segmentationId])) {
                  const segmentationName = catalogDetails._IndexedCatalogSegmentation[segmentationId].Name
                  newValue[segmentationName] = ''
                  oldValue[segmentationName] = ''
                  let newValueSegmentationString = ''
                  let oldValueString = ''
                  Object.keys(history.New[key][segmentationId]).forEach((segmentationDetail, index) => {
                    if (index !== 0) {
                      newValueSegmentationString += ', '
                    }
                    newValueSegmentationString += `${t(`auditTrail.${segmentationDetail}`)}: ${history.New[key][segmentationId][segmentationDetail]}`
                  })
                  Object.keys(history.Old[key][segmentationId]).forEach((segmentationDetail, index) => {
                    if (index !== 0) {
                      oldValueString += ', '
                    }
                    oldValueString += `${t(`auditTrail.${segmentationDetail}`)}: ${history.Old[key][segmentationId][segmentationDetail]}`
                  })
                  newValue[segmentationName] = newValueSegmentationString
                  oldValue[segmentationName] = oldValueString
                }
              })
            }
            else if (key === '_prices') {
              propertyDisplayValue = t('auditTrail.prices')
              if (utils.isDefined(userStore.priceGroups.retail) && utils.isDefined(history.New[key][userStore.priceGroups.retail.Id])) {
                newValue[priceGroupsLabel.value.retailPrice] = history.New[key][userStore.priceGroups.retail.Id]
                oldValue[priceGroupsLabel.value.retailPrice] = history.Old[key][userStore.priceGroups.retail.Id]
              }
              if (utils.isDefined(userStore.priceGroups.wholesale) && utils.isDefined(history.New[key][userStore.priceGroups.wholesale.Id])) {
                newValue[priceGroupsLabel.value.wholesalePrice] = history.New[key][userStore.priceGroups.wholesale.Id]
                oldValue[priceGroupsLabel.value.wholesalePrice] = history.Old[key][userStore.priceGroups.wholesale.Id]
              }
              // TODO: Work on hideOutletPriceGroup
              if (utils.isDefined(userStore.priceGroups.outlet) && utils.isDefined(history.New[key][userStore.priceGroups.outlet.Id])) {
                newValue[priceGroupsLabel.value.outletPrice] = history.New[key][userStore.priceGroups.outlet.Id]
                oldValue[priceGroupsLabel.value.outletPrice] = history.Old[key][userStore.priceGroups.outlet.Id]
              }
            }
            else if (key === '_sizes') {
              propertyDisplayValue = t('auditTrail.sizes')
              const sizeAddedKey = 'SizeAdded'
              const sizeRemovedKey = 'SizeRemoved'
              newValue = { attribute: utils.isDefined(history.New[key]) && utils.isDefined(history.New[key][sizeAddedKey]) ? `${t('general.added')} ${history.New[key][sizeAddedKey]}` : '' }
              oldValue = { attribute: utils.isDefined(history.New[key]) && utils.isDefined(history.New[key][sizeRemovedKey]) ? `${t('general.removed')} ${history.New[key][sizeRemovedKey]}` : '' }
            }
            else if (key === '_requestedAttributes') {
              propertyDisplayValue = t('auditTrail.requestAttributes')
              Object.keys(history.New[key]).forEach((requestAttributeSystemName) => {
                if (userStore.myAttributes && utils.isDefined(userStore.myAttributes[requestAttributeSystemName])) {
                  newValue[userStore.myAttributes[requestAttributeSystemName].DisplayName] = history.New[key][requestAttributeSystemName]
                  oldValue[userStore.myAttributes[requestAttributeSystemName].DisplayName] = history.Old[key][requestAttributeSystemName]
                }
              })
            }
            else if (key === '_requestState') {
              propertyDisplayValue = t('auditTrail.requestState')
              const stateKey = 'State'
              const commentKey = 'Comment'
              const reasonKey = 'Reason'
              newValue[t('auditTrail.state')] = utils.isDefined(history.New[key]) && utils.isDefined(history.New[key][stateKey]) ? history.New[key][stateKey] : ''
              oldValue[t('auditTrail.state')] = utils.isDefined(history.Old[key]) && utils.isDefined(history.Old[key][stateKey]) ? history.Old[key][stateKey] : ''
              newValue[t('auditTrail.comment')] = utils.isDefined(history.New[key]) && utils.isDefined(history.New[key][commentKey]) ? history.New[key][commentKey] : ''
              oldValue[t('auditTrail.comment')] = utils.isDefined(history.Old[key]) && utils.isDefined(history.Old[key][commentKey]) ? history.Old[key][commentKey] : ''
              newValue[t('auditTrail.reason')] = utils.isDefined(history.New[key]) && utils.isDefined(history.New[key][reasonKey]) ? history.New[key][reasonKey] : ''
              oldValue[t('auditTrail.reason')] = utils.isDefined(history.Old[key]) && utils.isDefined(history.Old[key][reasonKey]) ? history.Old[key][reasonKey] : ''
            }
            else {
              propertyDisplayValue = userStore.myAttributes?.hasOwnProperty(key) ? userStore.myAttributes[key].DisplayName : key
              newValue = { attribute: utils.isDefined(history.New[key]) ? history.New[key].toString() : '' }
              oldValue = { attribute: utils.isDefined(history.Old[key]) ? history.Old[key].toString() : '' }
            }
            trail.changes.push({
              key,
              label: propertyDisplayValue,
              newValue,
              oldValue,
            })
          })
          if (trail.changes.length) {
            auditTrailHistory.value.push(trail)
          }
        }
      }
    })
      .catch(e => console.error(e))
      .finally(() => {
        const trail: ITrail = {
          changeDate: utils.formatDateTime(props.article.CreatedDate),
          changeMaker: props.article.Created_By_User,
          changes: [{ label: t('general.created'), key: 'created', newValue: {}, oldValue: {} }],
        }
        auditTrailHistory.value.push(trail)
        loading.value = false
      })
  }
}

// first load
onMounted(() => onLoad())

// watch
watch(() => props.article, () => {
  if (props.article) {
    onLoad()
  }
})
</script>
