<template>
  <div>
    <div ref="refSelect">
      <span class="inline-block w-full h-full">
        <tx-button
          v-if="icon" v-tooltip="tooltip" :icon="icon" :type="buttonType || 'icon'" :disabled="disabled"
          :ping="ping" @click="doOpenDropdown"
        />
        <tx-button
          v-else-if="faicon" v-tooltip="tooltip" :faicon="faicon" :type="buttonType || 'icon'" :disabled="disabled"
          :ping="ping" @click="doOpenDropdown"
        />
        <tx-button v-else v-tooltip="tooltip" :type="buttonType || 'text'" :ping="ping" :disabled="disabled" @click="doOpenDropdown">
          <slot name="button" />
        </tx-button>
      </span>
    </div>
    <div
      v-show="isOpen" ref="refDropdown" class="bg-white rounded-md z-dropdown shadow-dropdown focus:outline-none"
      :class="{ 'overflow-y-auto': items }" :style="{ maxHeight: `${maxHeight}px` }"
    >
      <template v-if="items">
        <tx-input
          v-if="filterable" ref="refFilter" v-model="filterText" clearable faicon="fa-light fa-magnifying-glass"
          class="mx-3" :placeholder="t('general.search')"
        />
        <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class>
          <li
            v-for="d in filteredData" id="listbox-item-0" :key="d" tabindex="0" role="option" class="relative flex items-center pl-2 pr-3 text-gray-900 cursor-pointer select-none h-9 hover:bg-[#ecf5ff] hover:text-[#66b1ff] focus:outline-none focus:bg-grey-light"
            @click="doSelect(d)"
          >
            <div class="text-base truncate grow" :class="{ 'text-primary-500 font-bold': isSelected(d) }">
              <font-awesome-icon v-if="hasIconProp" class="w-4 h-4 mr-1" :icon="d[iconProp]" />
              {{ hasDisplayProp ? d[displayProp] : d }}
            </div>
          </li>
          <li v-if="filteredData?.length === 0">
            <div class="flex items-center justify-center space-x-3">
              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <span class="block italic truncate text-dark-grey" v-text="t('general.emptyList')" />
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <slot />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { Instance } from '@popperjs/core'
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import TxInput from './TxInput.vue'
import TxButton from './TxButton.vue'

interface IProps {
  modelValue?: any
  items?: any[]
  valueProp?: string
  valueKey?: string
  displayProp?: string
  iconProp?: string
  filterable?: boolean
  disabled?: boolean
  icon?: string
  faicon?: string
  buttonType?: 'confirm' | 'danger' | 'default' | 'cancel' | 'text' | 'icon' | 'nav'
  tooltip?: string
  ping?: boolean
  maxHeight?: number
}
const props = withDefaults(defineProps<IProps>(), { valueProp: '', displayProp: '', iconProp: '', filterable: false, disabled: false, maxHeight: 224 })

const emit = defineEmits<{
  (e: 'update:modelValue', val: any): void
  (e: 'select', val: any): void
  (e: 'close'): void
}>()

const { t } = useI18n()
const refSelect = ref()
const refDropdown = ref()
const isOpen = ref(false)
const filterText = ref('')
const refFilter = ref<HTMLInputElement>()
let popper: Instance

const hasDisplayProp = computed(() => props.displayProp.length > 0)

const hasValueProp = computed(() => props.valueProp.length > 0)

const hasValueKey = computed(() => props.valueKey != null)

const hasIconProp = computed(() => props.iconProp.length > 0)

// const hasValue = computed(() => !isEmpty(props.modelValue))

function isSelected(val) {
  const v = hasValueProp.value ? val[props.valueProp] : val
  return hasValueKey.value ? props.modelValue[props.valueKey!] === val[props.valueKey!] : props.modelValue === v
}

function doCloseDropdown() {
  isOpen.value = false
  emit('close')
}

function doOpenDropdown() {
  if (!props.disabled) {
    isOpen.value = true
    popper.update()
    if (props.filterable) {
      nextTick(() => {
        refFilter.value?.focus()
      })
    }
  }
}

function doSelect(val) {
  isOpen.value = false
  emit('update:modelValue', hasValueProp.value ? val[props.valueProp] : val)
  emit('select', val)
}

const filteredData = computed(() => {
  if (!props.items) { return [] }
  if (filterText.value.length === 0) { return props.items }
  const filterTextLowercase = filterText.value.toLowerCase()
  return props.items.filter((itm) => {
    if (hasDisplayProp.value) {
      return itm[props.displayProp].toString().toLowerCase().includes(filterTextLowercase)
    }
    else {
      return itm.toString().toLowerCase().includes(filterTextLowercase)
    }
  })
})

onMounted(() => {
  popper = createPopper(refSelect.value, refDropdown.value, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [20, 5],
        },
      },
    ],
  })

  onClickOutside(refDropdown.value, () => {
    doCloseDropdown()
  })
})

onUnmounted(() => {
  if (popper) {
    popper.destroy()
  }
})

defineExpose({
  closeDropdown: doCloseDropdown,
  openDropdown: doOpenDropdown,
})
</script>
