<template>
  <form-attribute-label
    v-show="showLabel"
    v-model:checked="checkedModel"
    :attribute="attribute"
    :disabled="disabled || attribute.AttributeType === AttributeType.LookupTable"
    :required="required"
    :show-checkbox="showCheckbox"
    :show-external-change-management-info="showExternalChangeManagementInfo"
    :show-update-seasonless-attribute-warning="showUpdateSeasonlessAttributeWarning"
    :tooltip-placement="tooltipPlacement"
    :tooltip-width="tooltipWidth"
    :show-obsolete-master-sizescale-warning="showObsoleteMasterSizescaleWarning"
    @check-changed="onCheckChanged"
  />
  <color-palette-autocomplete
    v-if="attribute.AttributeType === AttributeType.ColorPalette" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :form="form"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    :articles="articles"
    :is-primary-color="attribute.SystemName === 'ColorId'"
    :color-name="form.ColorName"
    :pre-fill-color="preFillColor"
    @change="handleChange"
  />
  <!-- master size scale and size scale are coupled and dependent to each other, when adding size scale we should always add master size scale as well -->
  <master-size-scale-autocomplete
    v-else-if="attribute.AttributeType === AttributeType.MasterSizeScale" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :form="form"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    :articles="articles"
    :attribute="attribute"
    :should-consider-obsolete-model-value="shouldConsiderObsoleteModelValue"
    :should-allow-reset-existing-value="shouldAllowResetExistingValue"
    :is-master-sizescale-obsolete="showObsoleteMasterSizescaleWarning"
    @reset-size-scale="$emit('resetSizeScale')"
    @change="handleChange"
  />
  <size-scale-autocomplete
    v-else-if="attribute.AttributeType === AttributeType.SizeScale" ref="controlRef"
    v-model="attributeEditorModelValue"
    v-bind="$attrs"
    :form="form"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    :articles="articles"
    :attribute="attribute"
    :should-consider-obsolete-model-value="shouldConsiderObsoleteModelValue"
    @change="handleChange"
  />
  <!-- FORM EDITOR -->
  <form-editor
    v-else ref="controlRef"
    v-model="attributeEditorModelValue"
    :attribute="attribute"
    :form="form"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    :errors="errors"
    :pattern="pattern"
    :show-label="false"
    @change="handleChange"
  />
</template>

<script setup lang='ts'>
import type { ErrorObject } from '@vuelidate/core'
import { computed, nextTick, ref, watch } from 'vue'
import FormAttributeLabel from './FormAttributeLabel.vue'
import ColorPaletteAutocomplete from './ColorPaletteAutocomplete.vue'
import type Article from '@/models/article'
import FormEditor from '@/shared/components/FormEditor.vue'
import MasterSizeScaleAutocomplete from '@/shared/components/MasterSizeScaleAutocomplete.vue'
import type MyArticle from '@/models/myArticle'
import SizeScaleAutocomplete from '@/shared/components/SizeScaleAutocomplete.vue'
import { AttributeType } from '@/models/catalogAttribute'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), {
  checked: false,
  clearable: true,
  disabled: false,
  required: false,
  errors: () => [] as ErrorObject[],
  form: () => ({}),
  showCheckbox: false,
  showExternalChangeManagementInfo: false,
  showLabel: true,
  showUpdateSeasonlessAttributeWarning: true,
  shouldConsiderObsoleteModelValue: false,
  shouldAllowResetExistingValue: true,
  preFillColor: true,
})

const emit = defineEmits<{
  (e: 'change', modelValue: any): void
  (e: 'checkChanged', checked: boolean): void
  (e: 'update:checked', checked: boolean): void
  (e: 'update:modelValue', val: any): void
  (e: 'resetSizeScale'): void
}>()

interface IProps {
  attribute: IAttributeEditorAttribute
  checked?: boolean
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  errors?: ErrorObject[]
  form: Record<string, any>
  modelValue?: any
  showCheckbox?: boolean
  showExternalChangeManagementInfo?: boolean
  showLabel?: boolean
  showUpdateSeasonlessAttributeWarning?: boolean
  tooltipPlacement?: string
  tooltipWidth?: string
  shouldConsiderObsoleteModelValue?: boolean
  shouldAllowResetExistingValue?: boolean
  showObsoleteMasterSizescaleWarning?: boolean
  pattern?: string
  preFillColor?: boolean
  articles: Array<MyArticle | Article> // TODO: use wrapper, move article and dependent component, encapsulate validations, common logics etc
}

const controlRef = ref<HTMLElement>()

const attributeEditorModelValue = computed({
  get: () => props.modelValue,
  set: modelValue => emit('update:modelValue', modelValue),
})

const checkedModel = ref(props.checked)

function onCheckChanged(checked) {
  emit('update:checked', checked)
  emit('checkChanged', checked)
}

function handleChange() {
  nextTick(() => {
    emit('change', attributeEditorModelValue.value)
  })
}

watch(() => props.checked, (checked) => {
  checkedModel.value = checked
})

defineExpose({
  controlRef,
})
</script>
