<template>
  <tx-floating-toolbar
    v-show="!hide" :top="top" :left="left" :title="t('whiteboard.toolbars.objects.title')" :buttons="getToolbarButtons"
    :active-button="itemToAdd" vertical :max-height="maxToolbarHeight" @button-click="onButtonClick"
  />

  <input v-show="false" ref="fileInputRef" type="file" accept="image/png, image/jpg, image/jpeg" @change="onFileChange">

  <!-- Articles Selection toolbar -->
  <div
    v-show="itemToAdd === 'article'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar overflow-hidden pointer-events-auto"
    :style="{ top: '80px', left: '100px', width: '326px', bottom: '80px' }"
  >
    <articles-finder
      v-if="itemToAdd === 'article'" v-model:columns="articleCols" :browse-by-model="false"
      :visible="itemToAdd === 'article'" @selection-changed="onArticleSelectionChanged"
    />
  </div>

  <div
    v-show="itemToAdd === 'model'" class="absolute z-[1000] bg-grey-light rounded-lg shadow-toolbar overflow-hidden pointer-events-auto"
    :style="{ top: '80px', left: '100px', width: '326px', bottom: '80px' }"
  >
    <articles-finder
      v-if="itemToAdd === 'model'" v-model:columns="articleCols" :browse-by-model="true"
      :visible="itemToAdd === 'model'" @selection-changed="onArticleSelectionChanged"
    />
  </div>

  <!-- Sticky color selection toolbar -->
  <div
    v-show="itemToAdd === 'sticky' && !hideStickyColor"
    class="absolute z-[1000] left-24 w-32 top-0 bottom-0 flex flex-col align-middle justify-center pointer-events-none"
  >
    <div class="flex-grow-0 block w-32 rounded-lg pointer-events-auto bg-grey-light shadow-toolbar">
      <div
        v-for="backgroundColor in availableColors" :key="backgroundColor" class="float-left w-12 h-12 m-2 shadow-sm cursor-pointer"
        :style="{ backgroundColor }" @click="onStickyColorClick(backgroundColor)"
      />
    </div>
  </div>

  <!-- Pen attribute selection toolbar -->
  <div
    v-show="itemToAdd === 'draw'"
    class="absolute z-[1000] left-24 w-32 top-0 bottom-0 flex flex-col align-middle justify-center pointer-events-none"
  >
    <div class="flex-grow-0 block w-40 p-2 rounded-lg pointer-events-auto bg-grey-light shadow-toolbar">
      <input v-model="penThickness" type="range" min="1" max="50" class="w-full" @input="onPenThicknessChange">
      <div
        v-for="backgroundColor in availableColors" :key="backgroundColor" class="rounded-full w-6 h-6 float-left m-1.5 cursor-pointer"
        :style="{ backgroundColor }" :class="{ 'outline outline-blue-50 outline-2 outline-offset-1': penColor === backgroundColor }"
        @click="onPenColorChange(backgroundColor)"
      />
    </div>
  </div>

  <!-- Shapes toolbar -->
  <div
    v-show="itemToAdd === 'shape'"
    class="absolute z-[1000] left-24 w-32 top-0 bottom-0 flex flex-col align-middle justify-center pointer-events-none"
  >
    <div class="flex-grow-0 block p-2 rounded-lg pointer-events-auto bg-grey-light shadow-toolbar w-28">
      <div
        v-for="(path, shapeType) in shapes" :key="shapeType" class="float-left w-10 h-10 p-1 m-1 cursor-pointer hover:text-primary-500"
        @click="onShapeClick(shapeType)"
      >
        <svg viewBox="0 0 50 50">
          <path :style="{ fill: 'none', stroke: selectedShape === shapeType ? 'blue' : '#000000', strokeWidth: '3px' }" :d="path" />
        </svg>
      </div>
    </div>
  </div>

  <!-- Frame Types toolbar -->
  <div
    v-show="itemToAdd === 'frame'"
    class="absolute z-[1000] left-24 w-52 top-48 bottom-0 flex flex-col align-middle justify-center pointer-events-none"
  >
    <div class="flex-grow-0 block w-32 p-2 rounded-lg pointer-events-auto bg-grey-light shadow-toolbar">
      <div
        v-for="(path, frameSize) in frameSizes" :key="frameSize" class="float-left w-12 p-1 m-1 cursor-pointer hover:text-primary-500"
        @click="onFrameSizeClick(frameSize)"
      >
        <div class="w-10 h-10">
          <svg viewBox="0 0 24 24">
            <path :style="{ fill: 'none', stroke: selectedFrameSize === frameSize ? 'blue' : '#000000' }" :d="path" />
          </svg>
        </div>
        <div class="text-center">
          {{ utils.capitalizeFirstLetter(frameSize) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { fabric } from 'fabric'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { onKeyStroke } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref, toRef, watchEffect } from 'vue'
import WbArticleImage from '../services/articleImage'
import WbTextBox from '../services/textBox'
import WbSticky from '../services/sticky'
import WbImage from '../services/image'
import type { ShapeType } from '../services/shape'
import WbShape from '../services/shape'
import WbPath from '../services/path'
import WbDiscussion from '../services/discussionIcon'
import type { FrameSize } from '../services/frame'
import WbFrame from '../services/frame'
import WbConnector from '../services/connector'
import WbModelImage from '../services/modelImage'
import type Whiteboard from '@/modules/whiteboard/services/whiteboard'
import type MyArticle from '@/models/myArticle'
import { upload } from '@/api/whiteboard/file'
import useConnecters from '@/modules/whiteboard/composables/connectors'
import { imageConstants, whiteboardConstants } from '@/models/constants'
import utils from '@/services/utils'
import ArticlesFinder from '@/shared/components/ArticlesFinder.vue'
import TxFloatingToolbar from '@/shared/components/TxFloatingToolbar.vue'

interface IProps {
  whiteboard?: Whiteboard
  collaborationReady: boolean
  left?: string
  top?: string
  right?: string
  bottom?: string
  hide?: boolean
}

const props = withDefaults(defineProps<IProps>(), { top: '100px', left: '20px', hide: false })

const emit = defineEmits<{
  (e: 'objectAdded', object: fabric.Object)
  (e: 'btnClick', btn: IToolbarButton<MerchToolbarAction>)
}>()

const { t } = useI18n()
const route = useRoute()
const itemToAdd = ref<MerchToolbarAction>('select')
const hideStickyColor = ref(false)
let stickyColor = '#f5d128'
const penThickness = ref(1)
const penColor = ref('#f16c7f')
const fileInputRef = ref<HTMLInputElement | null>()
const articleCols = ref(3)
let wb: Whiteboard
const { drawingConnector, switchAddConnectorMode, manageAddConnector } = useConnecters(toRef(props, 'whiteboard'), toRef(props, 'collaborationReady'))

const shapes = WbShape.shapes
const frameSizes = WbFrame.frameSizes
const availableColors = ['#c2c3c4', '#fff9b1', '#d5f692', '#f5d128', '#c9df56', '#ff9d48', '#93d275', '#f16c7f', '#67c6c0', '#ea94bb', '#6cd8fa', '#ffcee0', '#a6ccf5', '#be88c7', '#7b92ff', '#000000']

let selectedArticles: MyArticle[] = []
const selectedShape = ref<ShapeType>('rectangle')
const selectedFrameSize = ref<FrameSize>('custom')

const maxToolbarHeight = ref(0)

watchEffect(() => {
  if (props.whiteboard) {
    attach(props.whiteboard)
  }
})
const getToolbarButtons = computed(() => {
  const isButtonDisabled = !props.collaborationReady
  const buttons: IToolbarButton<MerchToolbarAction>[] = [
    { key: 'select', disabled: isButtonDisabled, faicon: 'fa-light fa-arrow-pointer', label: t('whiteboard.toolbars.actions.select'), visible: true },
    { key: 'text', disabled: isButtonDisabled, faicon: 'fa-light fa-text', label: t('whiteboard.toolbars.actions.text'), visible: true },
    { key: 'sticky', disabled: isButtonDisabled, faicon: 'fa-light fa-note-sticky', label: t('whiteboard.toolbars.actions.sticky'), visible: true },
    { key: 'shape', disabled: isButtonDisabled, faicon: 'fa-light fa-shapes', label: t('whiteboard.toolbars.actions.shape'), visible: true },
    // { key: 'connector', disabled: false, icon: ConnectorSvg, label: 'Connector', visible: true },
    { key: 'comment', disabled: isButtonDisabled, faicon: 'fa-light fa-comments', label: t('whiteboard.toolbars.actions.comment'), visible: true },
    { key: 'draw', disabled: isButtonDisabled, faicon: 'fa-light fa-pencil', label: t('whiteboard.toolbars.actions.draw'), visible: true },
    { key: 'article', disabled: isButtonDisabled, faicon: 'fa-light fa-square-plus', label: t('general.article'), visible: true },
    { key: 'model', disabled: isButtonDisabled, faicon: 'fa-light fa-folder-plus', label: t('general.model'), visible: true },
    { key: 'frame', disabled: isButtonDisabled, faicon: 'fa-light fa-frame', label: t('whiteboard.toolbars.actions.frame'), visible: true },
    { key: 'upload', disabled: isButtonDisabled, faicon: 'fa-light fa-upload', label: t('whiteboard.toolbars.actions.upload'), visible: true },
    { key: 'generate', disabled: isButtonDisabled, faicon: 'fa-light fa-clapperboard-play', label: t('whiteboard.toolbars.actions.generate'), visible: true },
    { key: 'dynamic', disabled: isButtonDisabled, faicon: 'fa-light fa-grid-dividers', label: t('whiteboard.toolbars.actions.dynamic'), visible: true },
  ]
  return buttons
})
onUnmounted(() => {
  if (wb) {
    wb.canvas.off('mouse:up', onMouseUp)
  }
  window.removeEventListener('resize', onResize)
})

onMounted(() => {
  onResize()
  window.addEventListener('resize', onResize)
})

function onResize() {
  maxToolbarHeight.value = window.innerHeight - 200
}

function attach(whiteboard: Whiteboard) {
  wb = whiteboard
  wb.canvas.on('mouse:up', onMouseUp)
  wb.canvas.freeDrawingBrush.color = penColor.value
  wb.canvas.freeDrawingBrush.width = penThickness.value
}

function onButtonClick(btn: IToolbarButton<MerchToolbarAction>) {
  itemToAdd.value = btn.key
  if (!['select', 'article', 'draw', 'upload', 'connector'].includes(btn.key)) {
    wb.canvas.defaultCursor = 'crosshair'
  }
  else {
    wb.canvas.defaultCursor = 'default'
  }
  wb.canvas.isDrawingMode = btn.key === 'draw'
  hideStickyColor.value = false

  if (btn.key === 'upload') {
    fileInputRef.value?.click()
    itemToAdd.value = 'select'
  }
  else if (btn.key === 'connector') {
    switchAddConnectorMode()
  }
  emit('btnClick', btn)
}

function onMouseUp(e: fabric.IEvent<MouseEvent | Event>) {
  let newObj: IWbObject | null = null
  switch (itemToAdd.value) {
    case 'text':
      newObj = new WbTextBox('Enter text', { top: e.absolutePointer?.y, left: e.absolutePointer?.x })
      break

    case 'sticky':
      newObj = new WbSticky('Enter text', { top: e.absolutePointer?.y, left: e.absolutePointer?.x, width: 100, height: 100, fill: stickyColor })
      break

    case 'shape':
      newObj = new WbShape(selectedShape.value, {
        top: e.absolutePointer?.y,
        left: e.absolutePointer?.x,
        width: 100,
        height: 100,
        fill: '#93d275',
        stroke: '#000000',
        strokeMiterLimit: 10,
        opacity: 1,
      })
      break

    case 'comment':
      newObj = new WbDiscussion({ top: e.absolutePointer?.y, left: e.absolutePointer?.x })
      break

    case 'frame': {
      const number = (wb.canvas.getObjects(whiteboardConstants.objectTypes.frame).length + 1)
      const frameName = `Frame ${number}`
      newObj = new WbFrame(selectedFrameSize.value, { top: e.absolutePointer?.y, left: e.absolutePointer?.x, name: frameName, sortOrder: number })
      break
    }

    case 'dynamic':
      wb?.addDynamicGridObj({ x: e.absolutePointer?.x || 0, y: e.absolutePointer?.y || 0 })
      break

    case 'draw':
      if (e.currentTarget) {
        const fabricPath = e.currentTarget as fabric.Path
        newObj = new WbPath(fabricPath.path, fabricPath.toObject())
        // removing the free hand object and replace it with `WbPath`
        wb.canvas.remove(fabricPath)
      }
      break

    case 'model':
      selectedArticles.forEach((art, i) => {
        const top = (e.absolutePointer?.y || 0) + Math.trunc(i / articleCols.value) * 100
        const left = (e.absolutePointer?.x || 0) + 100 * (i % articleCols.value)
        WbModelImage.loadModelImage(art, 500, 500, { top, left, catalogCode: art.CatalogCode, articleId: art.Id, modelNumber: art.ModelNumber })
          .then((newImg) => {
            wb.addObjects([newImg], true)
            emit('objectAdded', newImg)
          })
      })
      selectedArticles = []
      wb.canvas.requestRenderAll()
      break

    case 'article':
      selectedArticles.forEach((art, i) => {
        const top = (e.absolutePointer?.y || 0) + Math.trunc(i / articleCols.value) * 100
        const left = (e.absolutePointer?.x || 0) + 100 * (i % articleCols.value)
        WbArticleImage.loadArticleImage(art, 500, 500, { top, left, catalogCode: art.CatalogCode, articleId: art.Id, objectId: art.CatalogArticleId, isRequest: art._IsRequestArticle }).then((newImg) => {
          wb.addObjects([newImg], true)
          emit('objectAdded', newImg)
        })
      })
      selectedArticles = []
      wb.canvas.requestRenderAll()
      break

    case 'connector':
      manageAddConnector(e)
      // even though connectors are not selectable clicking on a object that already associated with connector (on bounding rect of connector) will consider click on connector
      if (e.target == null || (!(e.target as IWbObject).connectable && !(e.target instanceof WbConnector))) {
        itemToAdd.value = 'select'
      }
      break

    default:
      break
  }

  resetItemToAdd()
  if (newObj) {
    wb.addObjects([newObj], true)

    // Check the frame so that it can be added to it
    const frames = wb.canvas.getObjects(whiteboardConstants.objectTypes.frame)
    if (frames.length > 0) {
      for (let i = 0; i < frames.length; i++) {
        const frame = frames[i] as WbFrame
        if (newObj.id && newObj.isContainedWithinObject(frame)) {
          frame.addChild(newObj.id)
          break
        }
      }
    }

    emit('objectAdded', newObj)
  }
}

onKeyStroke('Escape', () => {
  resetItemToAdd()
})

function resetItemToAdd() {
  if (itemToAdd.value !== 'draw' && (itemToAdd.value !== 'connector' || !drawingConnector.value)) {
    wb.canvas.defaultCursor = 'default'
    wb.canvas.hoverCursor = 'move'
    itemToAdd.value = 'select'
  }
}

function onArticleSelectionChanged(articles: MyArticle[]) {
  if (itemToAdd.value !== 'article' && itemToAdd.value !== 'model') {
    return
  }
  selectedArticles = articles
  if (articles.length > 0) {
    wb.canvas.defaultCursor = 'crosshair'
  }
  else {
    wb.canvas.defaultCursor = 'default'
  }
}

function onStickyColorClick(color: string) {
  stickyColor = color
  hideStickyColor.value = true
}

function onPenThicknessChange() {
  wb.canvas.freeDrawingBrush.width = penThickness.value
}

function onPenColorChange(color: string) {
  penColor.value = color
  wb.canvas.freeDrawingBrush.color = color
}

function onFileChange() {
  if (!fileInputRef.value || !fileInputRef.value.files || !fileInputRef.value.files[0]) { return }

  const file = fileInputRef.value.files[0]
  if (imageConstants.validImageFormats.has(file.type) && file.size <= imageConstants.maxImageSize) {
    upload(route.params.whiteboardId.toString(), file)
      .then(async (res) => {
        if (res.data && res.data.url) {
          const options: fabric.IImageOptions = {}
          if (wb.canvas.vptCoords) {
            options.top = wb.canvas.vptCoords.tl.y
            options.left = wb.canvas.vptCoords.tl.x
          }
          const img = await WbImage.loadFromUrl(res.data.url, options)
          if (img && img.width && img.height) {
            const max_size = 250
            let scaleX = 100
            let scaleY = 100
            const scale = 100
            if (img.width > img.height) {
              if (img.width > max_size) {
                const height = img.height * (max_size / img.width)
                scaleY = (height / img.height) * 100
                scaleX = (max_size / img.width) * 100
              }
            }
            else {
              if (img.height > max_size) {
                const width = img.width * (max_size / img.height)
                scaleX = (width / img.width) * 100
                scaleY = (max_size / img.height) * 100
              }
            }
            img.setProp('scale', { scaleX, scaleY, scale })
          }
          wb.addObjects([img], true)
          fileInputRef.value!.value = ''
        }
        else {
          console.warn('Failed to upload image')
        }
      }).catch((err) => {
        console.warn('Failed to upload image', err)
      })
  }

  // const reader = new FileReader()
  // reader.onload = function (e) {
  //   var image = new Image()
  //   image.src = (e.target!.result as string)
  //   image.onload = function () {
  //     var img = new WbImage(image)
  //     if ((img.width && img.height) && (img.width > 750 || img.height > 750)) {
  //       if (img.width > img.height) {
  //         img.scaleToWidth(750)
  //       } else {
  //         img.scaleToHeight(750)
  //       }
  //     }
  //     wb.canvas.add(img)
  //     const vpCoords = wb.canvas.vptCoords
  //     if (vpCoords) {
  //       img.set({
  //         top: vpCoords.tl.y,
  //         left: vpCoords.tl.x
  //       })
  //       wb.canvas.requestRenderAll()
  //     }

  //     fileInputRef.value!.value = ''
  //   }
  // }
  // reader.readAsDataURL(imgData)
}

function onShapeClick(shapeType: ShapeType) {
  selectedShape.value = shapeType
}

function onFrameSizeClick(frameSize: FrameSize) {
  selectedFrameSize.value = frameSize
}
</script>
