<template class="container relative flex flex-col w-full h-full overflow-y-auto">
  <tx-dialog
    v-model="visible"
    :title="t('merch.dialog.editSlide.title')"
    width="30%"
    height="75%"
    :show-ok-cancel="props.allowEditing"
    :ok-state="!hasFormChanged ? 'disabled' : (v$.$invalid ? 'disabled' : 'enabled')"
    :loading="loading"
    confirm-text="general.save"
    @ok="doUpdate"
  >
    <div class="w-full h-full overflow-x-hidden">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div
          v-for="(attribute, index) in formFields"
          :key="attribute.SystemName"
          :class="{ 'col-span-2': index === 0, 'col-span-1': index > 0 }"
          class="mb-5 last-of-type:mb-4"
        >
          <form-editor
            v-if="attribute.SystemName !== 'Folder'"
            v-model="formModel[attribute.SystemName]"
            :attribute="attribute"
            :form="formModel"
            :required="attribute.IsRequired"
            :disabled="!props.allowEditing"
            :errors="v$[attribute.SystemName]?.$errors"
            :show-label="true"
            :clearable="true"
            @blur="v$[attribute.SystemName]?.$touch"
            @change="onChange()"
          />
          <div v-else class="mb-5 last-of-type:mb-4">
            <div v-if="props.allowEditing">
              <parent-folder-editor v-model="formModel[attribute.SystemName]" :slide-tree="props.slideTree" :merch="props.merch" :allow-editing="props.allowEditing" @update="onFolderUpdate" />
            </div>
            <div v-else>
              <tx-input
                v-model="formModel[attribute.SystemName]"
                :label="t('merch.dialog.addSlide.fields.folder')" :disabled="true"
              />
            </div>
          </div>
        </div>
        <div class="info-container mb-5 last-of-type:mb-4 col-span-2">
          <div>
            <span class="text-gray-500"> {{ t('merch.dialog.editSlide.fields.owner') }}: {{ readOnlyData.CreatedByName }} </span>
          </div>
          <div>
            <span class="text-gray-500"> {{ t('merch.dialog.editSlide.fields.ownerUsername') }}: {{ readOnlyData.CreatedByUserName }} </span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-500">
              {{ t('merch.dialog.editSlide.fields.createdOn') }}: {{ utils.formatDateTime(readOnlyData.CreatedDate) }}
            </span>
            <span class="text-gray-500">
              {{ t('merch.dialog.editSlide.fields.updatedOn') }}: {{ utils.formatDateTime(readOnlyData.UpdatedDate) }}
            </span>
          </div>
          <div>
            <span class="text-gray-500"> {{ t('merch.dialog.editSlide.fields.sharedWith') }}: </span>
            <div class="border border-gray-300 p-4 h-[115px] overflow-y-auto">
              <div v-for="(sharedWith, index) in readOnlyData.sharedWithUsersList" :key="index" class="container">
                <div class="flex items-center">
                  <font-awesome-icon class="w-4 h-4 mx-0.5 cursor-pointer text-primary-500" icon="fa-light fa-user" />
                  <span class="name ml-2">{{ `${sharedWith.FirstName} ${sharedWith.LastName} (${sharedWith.UserName})` }}</span>
                </div>
              </div>
              <div v-for="(sharedWith, index) in readOnlyData.sharedWithUserGroups" :key="index" class="container">
                <div class="flex items-center">
                  <font-awesome-icon class="w-4 h-4 mx-0.5 cursor-pointer text-primary-500" icon="fa-light fa-user-group" />
                  <span class="name ml-2">{{ `${sharedWith.Name} (${sharedWith.AccountName})` }}</span>
                </div>
              </div>
              <div v-for="(sharedWith, index) in readOnlyData.sharedWithFolders" :key="index" class="container">
                <div class="flex items-center">
                  <font-awesome-icon class="w-4 h-4 mx-0.5 cursor-pointer text-primary-500" icon="fa-light fa-folder" />
                  <span class="name ml-2">{{ sharedWith.folderPathName || sharedWith.FolderName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import type Merch from '../services/merch'
import type MerchSlide from '../services/merchSlide'
import ParentFolderEditor from './ParentFolderEditor.vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import { appConstants, merchConstants } from '@/models/constants'
import useErrorMessage from '@/shared/composables/errorMessage'
import { AttributeType } from '@/models/catalogAttribute'
import FormEditor from '@/shared/components/FormEditor.vue'
import utils from '@/services/utils'
import type { sharedUserGroupModel, sharedUserModel } from '@/api/t1/model/merchModel'
import TxInput from '@/shared/components/TxInput.vue'

interface ISharedFolder {
  FolderName: string
  folderPathName?: string
}
interface ReadOnlyData {
  CreatedByName: string
  CreatedByUserName: string
  CreatedDate: Date
  UpdatedDate: Date
  sharedWithFolders: ISharedFolder[]
  sharedWithUsersList: sharedUserModel[]
  sharedWithUserGroups: sharedUserGroupModel[]
}

const props = defineProps<{
  slideTree: ITreeNode[]
  merch: Merch | undefined
  allowEditing?: boolean
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const hasFormChanged = ref(false)
const formModel = reactive({
  SlideName: '',
  Folder: '',
  SlideSize: '',
  ImageSize: 0,
  BackgroundColor: '',
  slideId: '',
})

const slideImageSizes = [
  { label: t('general.large'), value: merchConstants.slideImageDefaultScaleFactors.large },
  { label: t('general.medium'), value: merchConstants.slideImageDefaultScaleFactors.medium },
  { label: t('general.small'), value: merchConstants.slideImageDefaultScaleFactors.small },
]
const slideSizes = [
  { label: t('merch.slideSizes.A4'), value: merchConstants.slideSize.A4 },
  { label: t('merch.slideSizes.A3'), value: merchConstants.slideSize.A3 },
  { label: t('merch.slideSizes.widescreen'), value: merchConstants.slideSize.wideScreen },
]
const readOnlyData: ReadOnlyData = {
  CreatedByName: '',
  CreatedByUserName: '',
  CreatedDate: new Date(),
  UpdatedDate: new Date(),
  sharedWithUsersList: [],
  sharedWithUserGroups: [],
  sharedWithFolders: [],
}
const parentNodeSelected = ref<ITreeNode | undefined>(undefined)
const formFields = computed(() => {
  const fields: Array<IMyAttribute> = [
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'SlideName',
      DisplayName: t('merch.dialog.editSlide.fields.slideName'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'Folder',
      DisplayName: t('merch.dialog.editSlide.fields.folder'),
      Creatable: true,
      IsRequired: true,
      showLabel: false,
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'SlideSize',
      DisplayName: t('merch.dialog.editSlide.fields.slideSize'),
      Creatable: true,
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
      DropDownData: slideSizes,
      DropDownValueProp: 'value',
      DropDownValueDisplayProp: 'label',
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'ImageSize',
      DisplayName: t('merch.dialog.editSlide.fields.imageSize'),
      AttributeType: AttributeType.Nvarchar,
      IsRequired: true,
      DropDownData: slideImageSizes,
      DropDownValueProp: 'value',
      DropDownValueDisplayProp: 'label',
    }),
    Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'BackgroundColor',
      DisplayName: t('merch.dialog.editSlide.fields.backgroundColor'),
      AttributeType: AttributeType.ColorPicker,
      IsRequired: false,
      inputColorType: 'rgb',
    }),
  ]
  return fields
})
const rules = computed(() => {
  const result: Record<string, any> = {}
  if (visible.value) {
    result.SlideName = {
      required: helpers.withMessage(t('validations.required', { property: t('merch.dialog.editSlide.fields.slideName') }), required),
      minLength: withI18nMessage(minLength(1)),
      maxLength: withI18nMessage(maxLength(250)),
    }
    result.ImageSize = { required: withI18nMessage(required) }
    result.SlideSize = { required: withI18nMessage(required) }
    result.Folder = {
      required: helpers.withMessage(t('validations.required', { property: t('merch.dialog.editSlide.fields.folder') }), required),
    }
  }
  return result
})
const v$ = useVuelidate(rules, formModel)

function populateForm(selectedSlideId: string, selectedNode?: ITreeNode | null) {
  if (props.merch && selectedSlideId) {
    formModel.slideId = selectedSlideId
    let selectedSlide: MerchSlide = props.merch.merchSlides.value[selectedSlideId]
    // allow editing false for shared slides
    if (!props.allowEditing) {
      selectedSlide = props.merch.sharedSlides.value[selectedSlideId]
    }
    if (selectedSlide) {
      formModel.SlideName = selectedSlide.SlideName
      const folderIdPath = selectedSlide.FolderId.split(merchConstants.folderPathSeparator)
      formModel.Folder = folderIdPath.pop()?.toString() || ''
      // if shared slide find the folder name from slidetree
      if (!props.allowEditing) {
        if (utils.isDefined(selectedNode)) {
          const isOwner = hasOwnerParent(selectedNode)
          if (!isOwner) {
            const folder = findParentPath(selectedNode)
            const folderParts = folder.name.split(merchConstants.folderPathSeparator)
            const parentFolderName = folderParts.slice(0, -1).join(merchConstants.folderPathSeparator)
            formModel.Folder = parentFolderName || formModel.Folder
          }
          else {
            formModel.Folder = selectedSlide.FolderName
          }
        }
      }
      formModel.SlideSize = selectedSlide.SlideSize
      formModel.ImageSize = selectedSlide.ImageSize
      formModel.BackgroundColor = selectedSlide.BackgroundColor
      // readonly properties
      readOnlyData.CreatedByName = selectedSlide.CreatedByName
      readOnlyData.CreatedByUserName = selectedSlide.CreatedByUserName
      readOnlyData.CreatedDate = selectedSlide.CreatedDate
      readOnlyData.UpdatedDate = selectedSlide.UpdatedDate
      readOnlyData.sharedWithUsersList = selectedSlide.SharedUsers
      readOnlyData.sharedWithUserGroups = selectedSlide.SharedUsersGroups
      const sharedFoldersList: ISharedFolder[] = []
      selectedSlide.SharedFolders.forEach((sharedFolder) => {
        const folderPathName = []
        searchFolderPathName(selectedSlide.SharedFolders, sharedFolder.Id, folderPathName)
        sharedFoldersList.push({ folderPathName: folderPathName.reverse().join(' / '), FolderName: sharedFolder.FolderName })
      })
      readOnlyData.sharedWithFolders = sharedFoldersList
    }
  }
}

function searchFolderPathName(tree, nodeId, pathNodes) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].Id === nodeId) {
      // it's parent
      pathNodes.push(tree[i].FolderName)
      if (tree[i].RootFolderId !== null) {
        searchFolderPathName(tree, tree[i].RootFolderId, pathNodes)
      }
    }
  }
}

function hasOwnerParent(node: ITreeNode) {
  if (!node || !node.parent) {
    return false
  }
  if (node.parent.isOwner) {
    return true
  }
  return hasOwnerParent(node.parent)
}

function showDialog(selectedSlideId, selectedNode?: ITreeNode | null) {
  reset()
  visible.value = true
  populateForm(selectedSlideId, selectedNode)
}

function reset() {
  loading.value = false
  formModel.SlideName = ''
  formModel.Folder = ''
  formModel.SlideSize = merchConstants.slideSize.wideScreen
  formModel.ImageSize = merchConstants.slideImageDefaultScaleFactors.large
  formModel.BackgroundColor = ''
  hasFormChanged.value = false
}

function onFolderUpdate(folder: string, parentNode: ITreeNode | undefined) {
  if (formModel.Folder !== folder) {
    hasFormChanged.value = true
  }
  formModel.Folder = folder
  parentNodeSelected.value = parentNode
}

// Function to find the parent path from a child node to the root
function findParentPath(childNode: ITreeNode | undefined) {
  const path: Record<string, any> = { id: [], name: [] }
  let currentNode = childNode

  // Traverse upward from the child node to the root
  while (utils.isDefined(currentNode)) {
    path.id.push(currentNode.key)
    path.name.push(currentNode.label)
    currentNode = currentNode.parent // Move to the parent node
  }
  const folderStructure = { id: '', name: '' }
  for (let i = path.id.length - 1; i >= 0; i--) {
    folderStructure.id += path.id[i] + (i !== 0 ? merchConstants.folderPathSeparator : '')
    folderStructure.name += path.name[i] + (i !== 0 ? merchConstants.folderPathSeparator : '')
  }
  return folderStructure
}

async function doUpdate() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
  }
  const folder = findParentPath(parentNodeSelected.value)
  const newFolderDetails = {
    FolderId: folder.id,
    FolderName: folder.name.trim(),
    FolderIdSortOrder: parentNodeSelected.value?.sortOrder,
    SortOrder: Math.max(...parentNodeSelected.value!.children.map(child => child.sortOrder), 0) + 1,
  }

  await props.merch?.updateSlide(formModel, formModel.slideId, newFolderDetails)
  loading.value = true
}

function onChange() {
  if (visible.value) {
    hasFormChanged.value = true
  }
}

defineExpose({
  showDialog,
  loading,
  visible,
  errorMessage,
})
</script>
