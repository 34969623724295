<template>
  <tx-dialog v-model="visible" :title="t('exportDialog.title')" width="90%" height="90%" @opened="onOpened" @closed="onClosed">
    <div class="flex flex-col w-full h-full">
      <!-- Steps -->
      <div class="px-6 py-4 bg-gray-100 border-t border-gray-200">
        <div class="flex justify-between">
          <template v-for="(step, index) in steps" :key="step">
            <div class="flex flex-col items-center">
              <div
                class="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                :class="[index <= currentStepIndex ? 'bg-blue-500 text-white border-blue-500' : 'border-gray-400']"
                v-text="index + 1"
              />
              <div class="text-sm" v-text="step" />
            </div>
            <div
              v-if="index < steps.length - 1" class="flex-1 h-2 mx-1 mt-3"
              :class="[index < currentStepIndex ? 'bg-blue-500' : 'bg-gray-400']"
            />
          </template>
        </div>
      </div>
      <!-- Content -->
      <div class="flex flex-1 w-full h-full gap-4 mt-4 overflow-hidden">
        <div>
          <img :src="jpgExport" class="h-full">
        </div>
        <div class="flex-1 p-1 overflow-y-auto">
          <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
          <!-- Step 1: Data -->
          <div v-show="currentStepIndex === 0">
            <div class="text-base">
              {{ t('exportDialog.steps.data.desc') }}
            </div>
            <!-- TODO: Implement Saved Options -->
            <div class="flex flex-col gap-2 mt-4">
              <label>
                <input v-model="form.source" type="radio" value="articles">
                <span class="ml-1">{{ t('exportDialog.steps.data.articles') }}</span>
              </label>
              <label>
                <input v-model="form.source" type="radio" value="merchBoard">
                <span class="ml-1">{{ t('exportDialog.steps.data.merchBoard') }}</span>
              </label>
              <label>
                <input v-model="form.source" type="radio" value="whiteboardFrame">
                <span class="ml-1">{{ t('exportDialog.steps.data.whiteboardFrame') }}</span>
              </label>
              <!-- <label v-if="isOrderModuleEnabled">
                <input v-model="form.source" type="radio" value="orders">
                <span class="ml-1">{{ t('exportDialog.steps.data.orders') }}</span>
              </label> -->
              <label v-if="isOrderModuleEnabled">
                <input v-model="form.source" type="radio" value="orderForm">
                <span class="ml-1">{{ t('exportDialog.steps.data.orderForm') }}</span>
              </label>
              <label v-if="userStore.activeCatalog?.RequestsEnabled">
                <input v-model="form.source" type="radio" value="requests">
                <span class="ml-1">{{ t('exportDialog.steps.data.requests') }}</span>
              </label>
            </div>
          </div>

          <!-- step 2 - Format -->
          <div v-show="currentStepIndex === 1">
            <div class="text-base">
              {{ t('exportDialog.steps.format.desc') }}
            </div>
            <div class="flex flex-col gap-2 mt-4">
              <label v-if="form.source === 'articles' || form.source === 'orders'">
                <input v-model="form.format" type="radio" value="pdf">
                <span class="ml-1">{{ t('exportDialog.steps.format.pdf') }}</span>
              </label>
              <label v-if="form.source !== 'whiteboardFrame'">
                <input v-model="form.format" type="radio" value="excel">
                <span class="ml-1">{{ t('exportDialog.steps.format.excel') }}</span>
              </label>
              <label v-if="form.source !== 'orderForm' && form.source !== 'requests'">
                <input v-model="form.format" type="radio" value="ppt">
                <span class="ml-1">{{ t('exportDialog.steps.format.ppt') }}</span>
              </label>
            </div>

            <transition
              enter-from-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
              leave-from-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0"
              class="w-full h-full mt-4"
            >
              <tx-select
                v-if="form.source === 'articles' && form.format === 'ppt'" v-model="form.templateId"
                :label="t('generateFrameDialog.steps.template.title')" :data="allowedTemplates" value-prop="id"
                display-prop="name" required filterable :errors="v$.templateId.$errors"
                @change="v$.templateId.$touch"
              />
              <tx-select
                v-else-if="form.source === 'articles' && form.format === 'pdf'" v-model="form.templateId"
                :label="t('generateFrameDialog.steps.template.title')" :data="allowedPDFTemplates" value-prop="id"
                display-prop="name" required filterable :errors="v$.templateId.$errors"
                @change="v$.templateId.$touch"
              />
            </transition>
          </div>

          <!-- step 3 - Filter -->
          <div v-show="currentStepIndex === 2">
            <div v-if="form.source === 'articles' || form.source === 'orderForm' || form.source === 'requests'">
              <div class="text-base">
                {{ t('exportDialog.steps.filter.desc') }}
              </div>
              <div v-if="articlesLimit">
                {{ t('exportDialog.steps.filter.limitDesc', { limit: articlesLimit }) }}
              </div>
              <div class="flex flex-col gap-2 mt-4">
                <label>
                  <input v-model="form.filter.type" type="radio" value="full">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.full') }}</span>
                </label>
                <label v-if="form.source !== 'requests'">
                  <input v-model="form.filter.type" type="radio" value="fav">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.fav') }}</span>
                </label>
                <label>
                  <input v-model="form.filter.type" type="radio" value="excel">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.excel') }}</span>
                </label>
                <label>
                  <input v-model="form.filter.type" type="radio" value="list">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.articles') }}</span>
                </label>
                <label>
                  <input v-model="form.filter.type" type="radio" value="modelList">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.models') }}</span>
                </label>
                <label v-if="form.format === 'ppt' && form.source === 'articles' && form.templateId === whiteboardConstants.frameTemplatesId.standard">
                  <input v-model="form.filter.type" type="radio" value="savedViews">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.savedViews') }}</span>
                </label>
                <label v-if="form.format === 'excel' && form.source === 'articles'">
                  <input v-model="form.filter.type" type="radio" value="whiteboard">
                  <span class="ml-1">{{ t('exportDialog.steps.filter.whiteboard') }}</span>
                </label>
              </div>

              <transition
                enter-from-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
                leave-from-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0"
                class="w-full h-full mt-4"
              >
                <tx-select
                  v-if="form.filter.type === 'fav'" v-model="form.filter.list"
                  :label="t('exportDialog.steps.filter.filterByTag')" :data="availableFavorites" value-prop="Id"
                  display-prop="Tag" secondary-display-prop="CreatedByUserName" required clearable filterable
                  multiple-values
                />
                <div v-else-if="form.filter.type === 'excel'">
                  <file-upload
                    v-model="form.filter.file" file-format-hint="Excel (xlsx)"
                    accept-format=".xlsx" @change="onFileChange"
                  />

                  <div class="mt-4">
                    <file-mapping-editor
                      v-if="form.format !== 'excel'" v-show="excelWorkbook" ref="refTemplateFileMappingEditor"
                      v-model="form.mapping" :template-id="form.templateId" :excel-workbook="excelWorkbook"
                      :current-step="currentStepIndex" :validation-step="2"
                    />
                    <excel-export-file-mapping-editor
                      v-if="form.format === 'excel'" v-show="excelWorkbook" ref="refExcelExportFileMappingEditor"
                      v-model="form.mapping" :template-id="form.templateId" :excel-workbook="excelWorkbook"
                      :current-step="currentStepIndex" :validation-step="2" :format="form.format"
                    />
                  </div>
                </div>
                <tx-input
                  v-else-if="form.filter.type === 'list'" v-model="form.filter.text" style="height: 100px;"
                  type="textarea" :label="t('exportDialog.steps.filter.filterByArticleNumber')"
                  :placeholder="t('exportDialog.steps.filter.filterByArticleNumber')" required
                />
                <tx-input
                  v-else-if="form.filter.type === 'modelList'" v-model="form.filter.text" style="height: 100px;"
                  type="textarea" :label="t('exportDialog.steps.filter.filterByModelNumber')"
                  :placeholder="t('exportDialog.steps.filter.filterByModelNumber')" required
                />
                <tx-select
                  v-else-if="form.filter.type === 'savedViews'" v-model="form.filter.list" :label="t('exportDialog.steps.filter.savedViews')" :data="availableSavedViews"
                  value-prop="Id" display-prop="FullDisplayName" secondary-display-prop="Type" required clearable filterable multiple-values
                />
                <tx-select
                  v-else-if="form.filter.type === 'whiteboard'" v-model="form.filter.articlesWhiteboardId" :label="t('exportDialog.steps.filter.whiteboard')" required filterable clearable
                  :data="availableWhiteboards" value-prop="Id" display-prop="Name"
                />
              </transition>
            </div>

            <div v-else-if="form.source === 'whiteboardFrame'">
              <div class="w-full h-full my-2">
                <tx-select
                  v-model="form.filter.whiteboardId" :label="t('exportDialog.steps.filter.whiteboard')" required filterable clearable
                  :data="availableWhiteboards" value-prop="Id" display-prop="Name" @change="onWhiteboardChange"
                />
              </div>
              <div class="w-full h-full my-2">
                <tx-select
                  v-model="form.filter.whiteboardFrames" :label="t('exportDialog.steps.filter.selectWhiteboardFrame')"
                  :data="availableWhiteboardFrames" value-prop="key" display-prop="label" :loading="loadingFrames"
                  required filterable clearable multiple-values @change="onFramesChange"
                />
              </div>
            </div>
            <div v-else-if="form.source === 'merchBoard'" class="w-full h-full my-2">
              <div v-if="form.format !== 'excel'" class="h-16">
                <tx-select
                  v-model="form.filter.slideSize" :label="t('exportDialog.steps.filter.slideSize')" required filterable clearable
                  :data="slideSizes" value-prop="value" display-prop="label" @change="onSlideSizeChange"
                />
              </div>
              <div class="text-base my-2">
                {{ t('exportDialog.steps.filter.selectMerchBoardSlides') }}
              </div>
              <div class="h-[calc(100%_-_500px)] overflow-scroll">
                <loader v-if="loading" />
                <tx-tree
                  v-if="!loading" ref="refMerchSlideTree" class="tree" show-checkbox="onHoverOrOtherChecked" :data="merchTree" @check-change="OnNodeCheckedChange"
                />
              </div>
            </div>
          </div>

          <!-- Step 4: Options -->
          <div v-show="currentStepIndex === 3">
            <div v-if="form.source === 'articles' || (form.source === 'merchBoard' && form.format === 'excel')">
              <!-- TODO: Need to work on predefined options -->
              <options-editor
                v-if="form.format === 'ppt' || form.format === 'pdf'" ref="refTemplateOptionsEditorExport" :template-id="form.templateId"
                :show-include-inactive-articles="form.filter.type === 'savedViews' ? false : true" :current-step="currentStepIndex"
                :format="form.format === 'pdf' ? 'pdf' : 'frames'" :validation-step="3" @update="updateFormOptions"
              />
              <!-- excel export options -->
              <export-options-editor v-else-if="form.format === 'excel'" v-model="form.options" :show-include-inactive-articles="form.source !== 'merchBoard'" :source="form.source" :current-step="currentStepIndex" :validation-step="3" />
            </div>
            <div v-else-if="form.source === 'orderForm'">
              <export-order-form-options-editor v-model="form.options" :current-step="currentStepIndex" :validation-step="3" :linked-customers="linkedCustomers" :linked-locations="linkedLocations" :vas-data="vasData" />
            </div>
            <div v-if="form.source === 'requests'">
              <export-options-editor
                v-model="form.options" :source="form.source" :current-step="currentStepIndex" :validation-step="-1"
                :show-include-images="false" :show-favorite-tags-in-separate-row="false"
              />
            </div>
          </div>

          <!-- Step 5: Review -->
          <div v-show="currentStepIndex === 4">
            <div class="text-base">
              {{ t('exportDialog.steps.review.desc') }}
            </div>
            <table class="min-w-full mt-4">
              <tbody class="divide-y divide-gray-200">
                <tr>
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.steps.data.title') }}:
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ t(`exportDialog.steps.data.${form.source}`) }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.steps.format.title') }}:
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ t(`exportDialog.steps.format.${form.format}`) }}
                  </td>
                </tr>
                <tr v-if="form.source === 'articles' && form.format === 'ppt'">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('generateFrameDialog.steps.template.title') }}:
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ templates[form.templateId].name }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.steps.filter.title') }}:
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.source === 'whiteboardFrame' ? t('exportDialog.steps.filter.frames') : form.source === 'merchBoard' ? t('exportDialog.steps.filter.slides') : t(`exportDialog.steps.filter.${form.filter.type}`) }}
                  </td>
                </tr>
                <tr v-if="form.source !== 'whiteboardFrame' && form.source !== 'merchBoard' ">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ form.filter.type === 'modelList' && form.source !== 'orderForm' ? t('exportDialog.messages.validModels') : t('exportDialog.messages.validArticles') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.validDataCount }}
                  </td>
                </tr>
                <tr v-if="form.source !== 'whiteboardFrame' && form.source !== 'merchBoard' && (form.source === 'orderForm' || form.filter.type === 'list' || form.filter.type === 'modelList' || form.filter.type === 'excel' || form.filter.type === 'whiteboard')">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ form.filter.type === 'modelList' && form.source !== 'orderForm' ? t('exportDialog.messages.excludedModels') : t('exportDialog.messages.excludedArticles') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.invalidData.length }}
                  </td>
                </tr>
                <tr v-if="form.source !== 'whiteboardFrame' && form.source !== 'merchBoard' && form.reviewData.invalidData.length !== 0 && (form.source === 'orderForm' || form.filter.type === 'list' || form.filter.type === 'modelList' || form.filter.type === 'excel' || form.filter.type === 'whiteboard')">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ form.filter.type === 'modelList' && form.source !== 'orderForm' ? t('exportDialog.messages.excludedModelList') : t('exportDialog.messages.excludedArticleList') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.invalidData.join(', ') }}
                  </td>
                </tr>
                <tr v-if="form.source === 'whiteboardFrame'">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.messages.selectedFramesCount') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.validDataCount }}
                  </td>
                </tr>
                <tr v-if="form.source === 'whiteboardFrame'">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.messages.selectedFrames') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.selectedFrames.join(', ') }}
                  </td>
                </tr>
                <tr v-if="form.source === 'merchBoard'">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.messages.selectedSlidesCount') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.validDataCount }}
                  </td>
                </tr>
                <tr v-if="form.source === 'merchBoard'">
                  <td class="px-4 py-2 font-medium text-gray-700 whitespace-nowrap">
                    {{ t('exportDialog.messages.selectedSlides') }}
                  </td>
                  <td class="px-4 py-2 text-gray-500">
                    {{ form.reviewData.selectedSlides.join(', ') }}
                  </td>
                </tr>
                <tr v-if="form.format === 'excel' && form.options.includeImages && totalImages > 0">
                  <td class="p-4" colspan="2">
                    <tx-progress v-model="imageDownloadProgress" :title="imageDownloadProgressTitle" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex items-center justify-center px-6 py-4 space-x-3 sm:justify-end bg-gray-50">
        <tx-button
          type="cancel" :disabled="currentStepIndex <= 0" :loading="loading" :text="t('general.back')"
          width="113px" height="40px" @click="onBack"
        />
        <tx-button
          v-show="currentStepIndex < (steps.length - 1)" type="confirm" :loading="loading" :text="t('general.next')"
          width="113px" height="40px" :disabled="v$.$invalid" @click="onNext"
        />
        <tx-button
          v-show="currentStepIndex >= (steps.length - 1)" class="bg-green-800" type="confirm" :loading="loading" :disabled="form.reviewData.validDataCount === 0"
          :text="t('general.finish')" width="113px" height="40px" @click="onFinish"
        />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import tinycolor from 'tinycolor2'
import ExcelJS from 'exceljs'
import axios from 'axios'
import sharedb from 'sharedb/lib/client'
import { assign, clone, cloneDeep, sortBy, uniq, uniqBy } from 'lodash-es'
import ReconnectingWebSocket from 'reconnecting-websocket'
import pLimit from 'p-limit'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import { merchtoPptJson, toPptJson } from './utils'
import jpgExport from '@/assets/export.jpg'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxProgress from '@/shared/components/TxProgress.vue'
import FileUpload from '@/shared/components/FileUpload.vue'
import FileMappingEditor from '@/shared/components/templates/TemplateFileMappingEditor.vue'
import OptionsEditor from '@/shared/components/templates/TemplateOptionsEditor.vue'
import templates from '@/modules/whiteboard/services/templates'
import ExportOptionsEditor from '@/modules/export/ExportOptionsEditor.vue'
import ExportOrderFormOptionsEditor from '@/modules/export/ExportOrderFormOptionsEditor.vue'
import ExcelExportFileMappingEditor from '@/modules/export/ExcelExportFileMappingEditor.vue'
import type FavoriteTag from '@/models/favoriteTag'
import type Whiteboard from '@/models/whiteboard'
import { generate } from '@/api/fileServer/generate'
import { getArticlesAssets } from '@/api/t1/article'
import pdfTemplates from '@/modules/export/services/pdfTemplates'
import type { ExportRequest, ImageObject, ShapeObject, Slide, TextObject } from '@/api/fileServer/model/generateModel'
import { merchConstants, requestConstants, whiteboardConstants } from '@/models/constants'
import Job from '@/models/job'
import type Article from '@/models/article'
import type MyArticle from '@/models/myArticle'
import type SavedView from '@/models/savedView'
import { FilterCriteria, toFilterCriteria } from '@/models/filterCriteria'
import WbFrame, { frameSizes } from '@/modules/whiteboard/services/frame'
import useFilterableAttributes from '@/shared/composables/filterableAttributes'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type CustomerLocation from '@/models/CustomerLocation'
import type LinkedCustomer from '@/models/linkedCustomer'
import Order from '@/models/order'
import type { Size } from '@/models/articleSize'
import { fetchSellerVAS } from '@/api/t1/order'
import type { ISellerVASModel } from '@/api/t1/model/orderModel'
import { buildSlideTree, getGroupObjects } from '@/modules/merch/utils'
import { getMySlides, getSlideDetails } from '@/api/t1/merch'
import TxTree from '@/shared/components/TxTree.vue'
import Loader from '@/shared/components/Loader.vue'
import type { SlideModel } from '@/api/t1/model/merchModel'
import type { Segmentation } from '@/models/customerSegmentation'
import type CustomerSegmentation from '@/models/customerSegmentation'
import type RequestModel from '@/models/request'

interface IGenerateFrameForm {
  source: 'articles' | 'merchBoard' | 'whiteboardFrame' | 'orders' | 'orderForm' | 'requests'
  format: 'ppt' | 'pdf' | 'excel'
  templateId: number
  filter: {
    type: 'full' | 'fav' | 'excel' | 'list' | 'modelList' | 'savedViews' | 'whiteboard'
    list: number[]
    text: string
    file: File | null
    whiteboardId: number | null
    articlesWhiteboardId: number | null
    whiteboardFrames: string[]
    slideSize: string
    merchSelectedNodes: ITreeNode[]
  }
  mapping: Record<string, any>
  options: Record<string, any>
  reviewData: Record<string, any>
}
interface IExportData {
  articles: MyArticle[]
  excelData: Record<string, Record<string, string[]>> | undefined
  requestData: RequestModel[]
}
interface IcolumnHeader {
  header: string[]
  key: string
  width?: number
}
const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const steps = ['Data', 'Format', 'Filter', 'Options', 'Review']
const currentStepIndex = ref(0)
const availableFavorites = ref<FavoriteTag[]>([])
const availableWhiteboards = ref<Whiteboard[]>([])
const loadingFrames = ref(false)
const availableWhiteboardFrames = ref<IKeyLabel[]>([])
const refTemplateFileMappingEditor = ref<InstanceType<typeof FileMappingEditor>>()
const refExcelExportFileMappingEditor = ref<InstanceType<typeof ExcelExportFileMappingEditor>>()
const excelWorkbook = ref<ExcelJS.Workbook>()
const refTemplateOptionsEditorExport = ref<InstanceType<typeof OptionsEditor>>()
const { filterableAttributesByAttributeSystemName } = useFilterableAttributes()
const availableSavedViews = ref<SavedView[]>([])
const imageDownloadLimit = pLimit(10)
const totalImages = ref(0)
const downloadedImageCount = ref(0)
const activeSlides = ref<SlideModel[]>([])
const merchTree = ref<ITreeNode[]>([])
let linkedCustomers: LinkedCustomer[] = []
let linkedLocations: CustomerLocation[] = []
const refMerchSlideTree = ref<InstanceType<typeof TxTree>>()
let dbConn: sharedb.Connection | undefined
let dbDoc: sharedb.Doc | undefined
let vasData: ISellerVASModel[] = []

const allowedTemplates = computed(() => {
  // TODO: Need to check by catalog config 'AllowedMerchTemplates'
  // currently only standard is implemented
  return Object.values(templates)
})

const allowedPDFTemplates = computed(() => {
  // TODO: Need to check by catalog config 'AllowedMerchTemplates'
  return Object.values(pdfTemplates)
})

const imageDownloadProgress = computed(() => Math.round((downloadedImageCount.value / totalImages.value) * 100))
const imageDownloadProgressTitle = computed(() => t('exportDialog.steps.review.imageDownloadProgress') + (totalImages.value ? ` (${downloadedImageCount.value} / ${totalImages.value})` : ''))
const slideSizes = [
  { label: t('merch.slideSizes.A4'), value: merchConstants.slideSize.A4 },
  { label: t('merch.slideSizes.A3'), value: merchConstants.slideSize.A3 },
  { label: t('merch.slideSizes.widescreen'), value: merchConstants.slideSize.wideScreen },
]
const initialFormState: IGenerateFrameForm = {
  source: 'articles',
  format: 'pdf',
  templateId: allowedTemplates.value.length > 0 ? allowedTemplates.value[0].id : 0,
  filter: {
    type: 'full',
    list: [],
    text: '',
    file: null,
    whiteboardId: null,
    articlesWhiteboardId: null,
    whiteboardFrames: [],
    slideSize: merchConstants.slideSize.wideScreen,
    merchSelectedNodes: [],
  },
  mapping: {},
  options: {},
  reviewData: {
    invalidData: [],
    validDataCount: [],
    selectedFrames: [],
    selectedSlides: [],
  },
}

const initialExportFormState: IExportData = {
  articles: [],
  excelData: undefined,
  requestData: [],
}

const form = reactive<IGenerateFrameForm>(cloneDeep(initialFormState))
const exportData = reactive<IExportData>(initialExportFormState)
const orderLines = ref<MyArticle[]>([])
const rules = computed(() => {
  const result: Record<string, any> = {
    source: { required: withI18nMessage(required) },
    format: {},
    templateId: {},
    filter: {
      type: {},
      list: {},
      text: {},
      file: {},
      whiteboardId: {},
      articlesWhiteboardId: {},
      whiteboardFrames: {},
    },
    mapping: {},
    options: {},
  }
  if (currentStepIndex.value === 1) {
    result.format = { required: withI18nMessage(required) }
  }
  if (currentStepIndex.value === 2) {
    if (form.filter.type === 'fav' || form.filter.type === 'savedViews') {
      result.filter.list = { required: withI18nMessage(required) }
    }
    else if (form.filter.type === 'list' || form.filter.type === 'modelList') {
      result.filter.text = { required: withI18nMessage(required) }
    }
    else if (form.filter.type === 'excel') {
      result.filter.file = { required: withI18nMessage(required) }
    }
    else if (form.filter.type === 'whiteboard') {
      result.filter.articlesWhiteboardId = { required: withI18nMessage(required) }
    }
    if (form.source === 'whiteboardFrame') {
      result.filter.whiteboardId = { required: withI18nMessage(required) }
      result.filter.whiteboardFrames = { required: withI18nMessage(required) }
    }
    if (form.source === 'merchBoard') {
      result.filter.slideSize = { required: withI18nMessage(required) }
      result.filter.merchSelectedNodes = { required: withI18nMessage(required) }
    }
  }
  return result
})
const v$ = useVuelidate(rules, form)

const articlesLimit = computed(() => {
  let limit = 0
  if (userStore.activeCatalog) {
    if (form.format === 'ppt' || form.format === 'pdf') {
      limit = userStore.activeCatalog.Config.ExportTemplateArticlesLimit
    }
    else if (form.format === 'excel' && form.options.includeImages) {
      limit = userStore.activeCatalog.Config.ExportExcelArticlesWithImageLimit
    }
  }
  return limit
})

const isOrderModuleEnabled = computed(() => {
  let enabled = true
  if (userStore.activeCatalog && userStore.activeCatalog.Config.DisableModules.join(',').toLowerCase().split(',').includes('order')) {
    enabled = false
  }
  return enabled
})

const isCustomerRequiredDateValidatorAvailable = computed(() => Order.isCustomerRequiredDateValidatorAvailable(userStore.activeCatalog!))

function resetForm() {
  assign(form, cloneDeep(initialFormState))
  refTemplateFileMappingEditor.value?.reset()
  refTemplateOptionsEditorExport.value?.reset()
  refExcelExportFileMappingEditor.value?.reset()
  totalImages.value = 0
  downloadedImageCount.value = 0
}

function onOpened() {
  const ws = new ReconnectingWebSocket(`${appConfig.ShareDbUrl}/server?t=${encodeURIComponent(localStorage.getItem('tk') || '')}`)
  // eslint-disable-next-line ts/ban-ts-comment
  // @ts-expect-error
  dbConn = new sharedb.Connection(ws)
}

function onClosed() {
  if (dbConn) {
    dbConn.close()
    dbConn = undefined
  }

  if (dbDoc) {
    dbDoc.destroy()
    dbDoc = undefined
  }
}

async function showDialog() {
  currentStepIndex.value = 0
  resetForm()
  visible.value = true

  availableWhiteboardFrames.value = []
  if (userStore.activeCatalog) {
    availableFavorites.value = (await appConfig.DB!.favoriteTags
      .where({ CatalogCode: userStore.activeCatalog.CatalogCode, Status: 1 })
      .filter((tag) => {
        if (tag.CreatedByUserName === userStore.currentUsername) { return true }
        if (tag.SharedUsers && tag.SharedUsers.length > 0) { return true }
        if (tag.SharedUsersGroups && tag.SharedUsersGroups.length > 0) { return true }
        return false
      })
      .toArray()
    ).sort((a, b) => {
      if (a.CreatedByUserName === userStore.currentUsername) { return -1 }
      return a.Tag.localeCompare(b.Tag)
    })

    availableWhiteboards.value = await appConfig.DB!.whiteboards
      .where('CatalogCode').equals(userStore.activeCatalog!.CatalogCode)
      .toArray()

    const mySavedViews = await appConfig.DB!.savedViews
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, CreatedBy: userStore.userProfile.Id, IsCatalogLevel: 0, Status: 1 })
      .toArray()

    const catalogSavedViews = await appConfig.DB!.savedViews
      .where({ CatalogCode: userStore.activeCatalog!.CatalogCode, IsCatalogLevel: 1, Status: 1 })
      .toArray()

    availableSavedViews.value = mySavedViews.concat(catalogSavedViews)
  }
}
async function getVAS() {
  let sellerVAS: Array<ISellerVASModel> = []
  try {
    const sellerVASResponse = await fetchSellerVAS(userStore.activeCatalog!.AccountId)
    sellerVAS = sellerVASResponse.data
  }
  catch (error) {
    sellerVAS = []
    console.warn(error)
  }
  return sellerVAS
}
function onFileChange(file: File | null) {
  form.mapping.sheetId = ''
  if (file) {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target && event.target.result) {
        excelWorkbook.value = new ExcelJS.Workbook()
        excelWorkbook.value.xlsx.load(event.target.result as ArrayBuffer)
          .then((workbook) => {
            form.mapping.sheetId = workbook.worksheets.length > 0 ? workbook.worksheets[0].id : ''
          })
      }
    }
    reader.readAsBinaryString(file)
  }
}

function onWhiteboardChange(val: number) {
  loadingFrames.value = true
  availableWhiteboardFrames.value = []
  form.filter.whiteboardFrames = []
  const boardId = `${appConfig.T1Env}${val}`
  if (dbConn) {
    dbDoc = dbConn.get('boards', boardId)
    dbDoc.subscribe((err) => {
      if (err) {
        console.error(err)
        loadingFrames.value = false
        return
      }

      if (dbDoc) {
        for (const key in dbDoc.data) {
          if (key !== 'version' && key !== 'objectOrder' && dbDoc.data[key].type === whiteboardConstants.objectTypes.frame) {
            availableWhiteboardFrames.value.push({
              key,
              label: dbDoc.data[key].name,
            })
          }
        }
        loadingFrames.value = false
      }
    })
  }
}
function onFramesChange(frameValues: string[]) {
  const selectedWhiteboard = availableWhiteboards.value.find(whiteboard => whiteboard.Id === form.filter.whiteboardId)
  form.reviewData.selectedFrames = []
  if (selectedWhiteboard) {
    frameValues.forEach((value) => {
      form.reviewData.selectedFrames.push(`${selectedWhiteboard.Name} - ${availableWhiteboardFrames.value.find(frame => frame.key === value)?.label}` || '')
    })
  }
  form.reviewData.validDataCount = form.reviewData.selectedFrames.length
}

function onBack() {
  if (currentStepIndex.value < 0) { return }

  if ((form.source === 'whiteboardFrame' || (form.source === 'merchBoard' && form.format !== 'excel')) && currentStepIndex.value === 4) {
    currentStepIndex.value = 2
  }
  else {
    currentStepIndex.value--
  }
}

async function onNext() {
  if (currentStepIndex.value >= (steps.length - 1)) { return }
  if (currentStepIndex.value === 0) {
    if (form.source === 'orderForm' || form.source === 'requests' || form.source === 'merchBoard') {
      form.format = 'excel'
    }
    else if (form.source === 'whiteboardFrame') {
      form.format = 'ppt'
    }
    currentStepIndex.value++
  }
  else if (currentStepIndex.value === 1) {
    loading.value = true
    currentStepIndex.value++
    await loadSlideTree()
    loading.value = false
  }
  else if (currentStepIndex.value === 2) {
    if (form.source === 'whiteboardFrame' || (form.source === 'merchBoard' && form.format !== 'excel')) {
      currentStepIndex.value = 4
    }
    else if (form.source === 'orderForm') {
      linkedCustomers = await getCustomers()
      linkedLocations = await getLocations()
      vasData = await getVAS()
      currentStepIndex.value++
    }
    else {
      currentStepIndex.value++
    }
  }
  else if (currentStepIndex.value === 3) {
    if (form.source === 'requests') {
      loading.value = true
      await getExportData(form.options.includeInactiveArticles)
      loading.value = false
    }
    else if (form.source === 'orderForm') {
      loading.value = true
      await getExportData(false)
      if (form.source === 'orderForm') {
        orderLines.value = getDataToExportForOrderForm(exportData.articles)
      }
      loading.value = false
    }
    if (form.source === 'articles') {
      loading.value = true
      if (form.filter.type === 'whiteboard') {
        await getExportDataForWhiteboard()
      }
      else {
        await getExportData(form.options.includeInactiveArticles)
      }
      loading.value = false
    }
    currentStepIndex.value++
  }
  else {
    currentStepIndex.value++
  }
}
async function getExportDataForWhiteboard() {
  const articleIdList: number[] = []
  const catalogToArticleIdMap = {}
  const boardId = `${appConfig.T1Env}${form.filter.articlesWhiteboardId}`
  exportData.articles = []
  form.reviewData.validDataCount = 0
  form.reviewData.invalidData = []
  if (dbConn) {
    dbDoc = dbConn.get('boards', boardId)
    dbDoc.subscribe(async (err) => {
      if (err) {
        console.error(err)
        loading.value = false
        return
      }

      if (dbDoc) {
        let counter = 0
        for (const key in dbDoc.data) {
          if (articlesLimit.value === 0 || counter < articlesLimit.value) {
            if (key !== 'version' && key !== 'objectOrder') {
              if (dbDoc.data[key].type === whiteboardConstants.objectTypes.articleImage) {
                const data = dbDoc.data[key]
                articleIdList.push(data.articleId)
                if (!catalogToArticleIdMap[data.catalogCode]) {
                  counter++
                  catalogToArticleIdMap[data.catalogCode] = [data.articleId]
                }
                else {
                  if (!catalogToArticleIdMap[data.catalogCode].includes(data.articleId)) {
                    counter++
                    catalogToArticleIdMap[data.catalogCode].push(data.articleId)
                  }
                }
              }
              else if (dbDoc.data[key].type === whiteboardConstants.objectTypes.dynamic && dbDoc.data[key].filterJson && dbDoc.data[key].filterJson.length !== 0) {
                const filter = dbDoc.data[key].filterJson?.map(v => new FilterCriteria(JSON.parse(v))) || []
                const gridArticles = await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog!, userStore.myAttributes!, filter, true, userStore.currentUsername, userStore.currentCustomer, userStore.currentCustomerSegmentations)
                for (let i = 0; i < gridArticles.length; i++) {
                  if (articlesLimit.value !== 0 && counter >= articlesLimit.value) {
                    break
                  }
                  const article = gridArticles[i]
                  articleIdList.push(article.Id)
                  if (!catalogToArticleIdMap[article.CatalogCode]) {
                    counter++
                    catalogToArticleIdMap[article.CatalogCode] = [article.Id]
                  }
                  else {
                    if (!catalogToArticleIdMap[article.CatalogCode].includes(article.Id)) {
                      counter++
                      catalogToArticleIdMap[article.CatalogCode].push(article.Id)
                    }
                  }
                }
              }
            }
          }
          else {
            break
          }
        }
        const catalogCodes = Object.keys(catalogToArticleIdMap)
        for (let i = 0; i < catalogCodes.length; i++) {
          const catalogCode = catalogCodes[i]
          const catalog = userStore.linkedCatalogDetails[catalogCode] ? userStore.linkedCatalogDetails[catalogCode] : userStore.activeCatalog
          let articles = await appConfig.DB!.getMyArticles(catalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, catalogToArticleIdMap[catalogCode], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
          if (!form.options.includeInactiveArticles) {
            articles = articles.filter(art => art.Status === 1)
          }
          exportData.articles.push(...articles)
        }
        loading.value = false
        articleIdList.forEach((id) => {
          const valid = exportData.articles.find(article => article.Id === id)
          if (!valid) {
            form.reviewData.invalidData.push(id)
          }
        })
        form.reviewData.validDataCount = exportData.articles.length
      }
    })
  }
}
async function getCustomers() {
  let linkedCustomers: Array<LinkedCustomer> = []
  if (!utils.isDefined(userStore.currentCustomer)) {
    try {
      if (userStore.activeCatalog) {
        linkedCustomers = await appConfig.DB!.getLinkedCustomers(userStore.activeCatalog, false)
      }
    }
    catch (error) {
      linkedCustomers = []
      console.warn(error)
    }
  }
  else {
    linkedCustomers = [userStore.currentCustomer]
  }
  return linkedCustomers
}

async function getLocations() {
  let locations: Array<CustomerLocation> = []
  try {
    if (!utils.isDefined(userStore.currentCustomer)) {
      locations = await appConfig.DB?.locations.where({ AccountId: userStore.activeCatalog?.AccountId }).toArray() ?? []
    }
    else {
      locations = await appConfig.DB?.locations.where({ CustomerId: userStore.currentCustomer.CustomerId }).toArray() ?? []
    }
  }
  catch (error) {
    locations = []
    console.error(error)
  }
  return locations
}
async function loadSlideTree() {
  if (userStore.activeCatalog) {
    const response = await getMySlides(userStore.activeCatalog.CatalogCode)
    const currentCustomerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null
    activeSlides.value = response.data.filter(slide => slide.Status === 1 && ((currentCustomerId === null && slide.CustomerId == null) || (currentCustomerId === slide.CustomerId)))
    if (activeSlides.value.length) {
      onSlideSizeChange()
    }
  }
}
function onSlideSizeChange() {
  const currentSlides: SlideModel[] = activeSlides.value.filter(slide => slide.SlideSize === form.filter.slideSize)
  if (currentSlides.length) {
    merchTree.value = buildSlideTree(currentSlides)
  }
}
function OnNodeCheckedChange() {
  const checkedData = refMerchSlideTree.value?.getCheckedNodes()
  form.filter.merchSelectedNodes = []
  if (utils.isDefined(checkedData) && checkedData.length > 0) {
    form.filter.merchSelectedNodes = checkedData.filter(node => node.isFolder === false)
  }
  form.reviewData.selectedSlides = []
  form.filter.merchSelectedNodes.forEach((value) => {
    form.reviewData.selectedSlides.push(`${value.parent ? value.parent.label : ''} - ${value.label}` || '')
  })

  form.reviewData.validDataCount = form.reviewData.selectedSlides.length
}
async function getArticlesValidDBData(list: string[], isArticleList: boolean, sortedCatalogCode: number[], isConsiderAllSeasonsData: boolean = true, includeInactiveArticles: boolean = false) {
  let articles: Article[] = []
  if (isArticleList) {
    const queryCriterion: Array<[number, string]> = []
    for (let index = 0; index < list.length; index++) {
      for (let codeIndex = 0; codeIndex < sortedCatalogCode.length; codeIndex++) {
        queryCriterion.push([+sortedCatalogCode[codeIndex], list[index]])
      }
    }
    articles = await appConfig.DB!.getArticlesByCatalogCodeArticleNumbers(userStore.activeCatalog!, queryCriterion, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
  }
  else {
    const queryCriterion: Array<[number, number, string]> = []
    for (let index = 0; index < list.length; index++) {
      for (let codeIndex = 0; codeIndex < sortedCatalogCode.length; codeIndex++) {
        queryCriterion.push([+sortedCatalogCode[codeIndex], 1, list[index]])
      }
    }
    articles = await appConfig.DB!.getArticlesByCatalogCodeStatusModelNumbers(userStore.activeCatalog!, queryCriterion, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
  }
  articles.sort((a, b) => {
    if (a.ArticleNumber.toLowerCase() === b.ArticleNumber.toLowerCase()) {
      return sortedCatalogCode.indexOf(a.CatalogCode) - sortedCatalogCode.indexOf(b.CatalogCode)
    }
    return a.ArticleNumber.toLowerCase() > b.ArticleNumber.toLowerCase() ? 1 : -1
  })
  if (!isConsiderAllSeasonsData) {
    articles = uniqBy(articles, 'ArticleNumber') as Array<Article>
  }
  return articles
}

async function getExportData(includeInactiveArticles = false) {
  const result: { articles: MyArticle[], excelData: Record<string, Record<string, string[]>> | undefined, requestData: RequestModel[] } = {
    articles: [],
    excelData: undefined,
    requestData: [],
  }
  let sortDataList: string[] = []
  let listData: any[] = []
  let validModels: any[] = []
  let articles: Article[] = []
  let isConsiderAllSeasonsData = true
  const requestsToModify: RequestModel[] = []
  let modifyRequestValidArticleNumberList: string[] = []
  if (form.format === 'ppt' && (form.templateId !== whiteboardConstants.frameTemplatesId.standard || (form.templateId === whiteboardConstants.frameTemplatesId.standard && !form.options.frameTitle.includes('_Seasons')))
    && (form.templateId !== whiteboardConstants.frameTemplatesId.visualLineBuilder
    || (form.templateId === whiteboardConstants.frameTemplatesId.visualLineBuilder && !form.options.verticalAttributes.includes('_Seasons') && !form.options.horizontalAttributes.includes('_Seasons') && !form.options.groupBy.includes('_Seasons')))) {
    isConsiderAllSeasonsData = false
  }
  const sortedCatalogCode = (form.format === 'ppt') ? utils.sort(Object.values(userStore.linkedCatalogDetails), ['SeasonalSequence'], false).map(catalog => catalog.CatalogCode) : []
  if (userStore.activeCatalog && userStore.myAttributes) {
    sortedCatalogCode.splice(0, 0, userStore.activeCatalog.CatalogCode)
    if (form.filter.type === 'full') {
      if (form.source === 'requests') {
        // fetch requests which is from another catalog and current catalog is part of the impacted catalogs list
        const requests = await appConfig.DB!.requests
          .toArray()
          .then(allRequests =>
            allRequests
              .filter(art =>
                (art.CatalogCode === userStore.activeCatalog!.CatalogCode && (includeInactiveArticles || art.Status === 1))
                || (art.RequestSource === requestConstants.requestSources.editArticle
                && (art.CatalogCode === userStore.activeCatalog!.CatalogCode
                || (art.ImpactedCatalogs && art.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)))),
              ),
          )

        for (const request of requests) {
          if (request.IsCreateArticleRequest === 1) {
            if (request.Content) {
              const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
              articles.push(requestArticle)
            }
          }
          else {
            requestsToModify.push(request)
          }
        }
      }
      else {
        const articleCollection = await appConfig.DB!.getArticleCollectionByCatalogCode(userStore.activeCatalog, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
        articles = await (articlesLimit.value > 0 ? articleCollection.limit(articlesLimit.value).toArray() : articleCollection.toArray())
      }
    }
    else if (form.filter.list.length && form.filter.type === 'fav') {
      const selectedFavs = await appConfig.DB!.favoriteTags.where('Id').anyOf(form.filter.list).toArray()
      const articleIds = new Set<number>()
      if (selectedFavs.length > 0) {
        selectedFavs.forEach((tag) => {
          if (tag.Articles.length > 0) {
            tag.Articles.forEach(artId => articleIds.add(artId))
          }
        })
      }
      if (articleIds.size > 0) {
        if (form.source === 'requests') {
          // fetch requests which is from another catalog and current catalog is part of the impacted catalogs list
          const requests = await appConfig.DB!.requests
            .where('SourceArticleId')
            .anyOf(Array.from(articleIds))
            .toArray()
            .then(allRequests =>
              allRequests
                .filter(art =>
                  (art.CatalogCode === userStore.activeCatalog!.CatalogCode && (includeInactiveArticles || art.Status === 1))
                  || (art.RequestSource === requestConstants.requestSources.editArticle
                  && (art.CatalogCode === userStore.activeCatalog!.CatalogCode || (art.ImpactedCatalogs && art.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)))),
                ),
            )
          for (const request of requests) {
            if (request.IsCreateArticleRequest !== 1) {
              requestsToModify.push(request)
            }
          }
        }
        else {
          const articleCollection = await appConfig.DB!.getArticleCollectionByCatalogCodeArticleIds(userStore.activeCatalog, articleIds, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
          articles = await (articlesLimit.value > 0 ? articleCollection.limit(articlesLimit.value).toArray() : articleCollection.toArray())
        }
      }
    }
    else if (utils.isValidStringValue(form.filter.text) && form.filter.type === 'list') {
      const articleNumbers = form.filter.text.split(/\r?\n/).map(s => s.trim())
      listData = clone(articleNumbers)
      sortDataList = form.filter.text.split(/\r?\n/).map(s => s.trim())
      if (articleNumbers.length > 0) {
        if (form.source === 'requests') {
          const listSet = new Set(listData)
          // fetch requests which is from another catalog and current catalog is part of the impacted catalogs list
          const requests = await appConfig.DB!.requests
            .toArray()
            .then(allRequests =>
              allRequests
                .filter(art =>
                  (art.CatalogCode === userStore.activeCatalog!.CatalogCode && (includeInactiveArticles || art.Status === 1))
                  || (art.RequestSource === requestConstants.requestSources.editArticle
                  && (art.CatalogCode === userStore.activeCatalog!.CatalogCode
                  || (art.ImpactedCatalogs && art.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)))),
                ),
            )
          const queryCriterion: Array<[number, string]> = []
          for (let index = 0; index < listData.length; index++) {
            queryCriterion.push([+userStore.activeCatalog.CatalogCode, listData[index]])
          }
          const sourceArticles = await appConfig.DB!.getArticlesByCatalogCodeArticleNumbers(userStore.activeCatalog!, queryCriterion, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
          modifyRequestValidArticleNumberList = sourceArticles.map(article => article.ArticleNumber)
          const articleIdList = new Set(sourceArticles.map(article => article.Id))
          for (const request of requests) {
            if (request.IsCreateArticleRequest === 1) {
              if (request.Content) {
                const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
                if (listSet.has(requestArticle.ArticleNumber)) {
                  articles.push(requestArticle)
                }
              }
            }
            else {
              if (request.Content && articleIdList.has(request.SourceArticleId!)) {
                requestsToModify.push(request)
              }
            }
          }
        }
        else {
          const limit = articlesLimit.value > 0 ? articlesLimit.value : articleNumbers.length
          while (articles.length < limit && articleNumbers.length !== 0) {
            const CurrentData = await getArticlesValidDBData(articleNumbers.splice(0, limit), true, sortedCatalogCode, isConsiderAllSeasonsData, includeInactiveArticles)
            articles.push(...CurrentData.splice(0, limit))
          }
        }
      }
    }
    else if (utils.isValidStringValue(form.filter.text) && form.filter.type === 'modelList') {
      const modelNumbers = form.filter.text.split(/\r?\n/).map(s => s.trim())
      listData = clone(modelNumbers)
      sortDataList = form.filter.text.split(/\r?\n/).map(s => s.trim())
      if (modelNumbers.length > 0) {
        if (form.source === 'requests') {
          const listSet = new Set(listData)
          // fetch requests which is from another catalog and current catalog is part of the impacted catalogs list
          const requests = await appConfig.DB!.requests
            .toArray()
            .then(allRequests =>
              allRequests
                .filter(art =>
                  (art.CatalogCode === userStore.activeCatalog!.CatalogCode && (includeInactiveArticles || art.Status === 1))
                  || (art.RequestSource === requestConstants.requestSources.editArticle
                  && (art.CatalogCode === userStore.activeCatalog!.CatalogCode
                  || (art.ImpactedCatalogs && art.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)))),
                ),
            )
          const queryCriterion: Array<[number, number, string]> = []
          for (let index = 0; index < listData.length; index++) {
            queryCriterion.push([+userStore.activeCatalog.CatalogCode, 1, listData[index]])
          }
          const sourceArticles = await appConfig.DB!.getArticlesByCatalogCodeStatusModelNumbers(userStore.activeCatalog!, queryCriterion, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
          modifyRequestValidArticleNumberList = sourceArticles.map(article => article.ModelNumber).filter((item, i, ar) => ar.indexOf(item) === i)
          const modelIdList = new Set(sourceArticles.map(article => article.ModelId))
          for (const request of requests) {
            if (request.IsCreateArticleRequest === 1) {
              if (request.Content) {
                const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
                if (listSet.has(requestArticle.ModelNumber)) {
                  articles.push(requestArticle)
                }
              }
            }
            else {
              if (request.Content && modelIdList.has(request.SourceModelId!)) {
                requestsToModify.push(request)
              }
            }
          }
        }
        else {
          if (articlesLimit.value > 0) {
            while (articles.length < articlesLimit.value && modelNumbers.length !== 0) {
              const CurrentData = await getArticlesValidDBData(modelNumbers.splice(0, articlesLimit.value), false, sortedCatalogCode, isConsiderAllSeasonsData, includeInactiveArticles)
              articles.push(...CurrentData.splice(0, articlesLimit.value))
            }
          }
          else {
            const CurrentData = await getArticlesValidDBData(modelNumbers, false, sortedCatalogCode, isConsiderAllSeasonsData, includeInactiveArticles)
            articles.push(...CurrentData)
          }
        }
      }
    }
    else if (form.filter.list.length && form.filter.type === 'savedViews') {
      const selectedSavedViews = await appConfig.DB!.savedViews.where('Id').anyOf(form.filter.list).toArray()
      // const addedArticleIds = new Set<Number>()
      if (selectedSavedViews.length > 0) {
        const filterCriteria: FilterCriteria[] = []
        for (const savedView of selectedSavedViews) {
          for (const attribute in savedView.Filter) {
            if (utils.isDefined(filterableAttributesByAttributeSystemName.value[attribute])) {
              filterCriteria.push(toFilterCriteria(attribute, savedView.Filter[attribute], userStore.activeCatalog!))
            }
          }
          // TODO check the implementation here
          articles = await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog, userStore.myAttributes, filterCriteria, true, userStore.currentUsername, userStore.currentCustomer, userStore.currentCustomerSegmentations)
          if (articlesLimit.value > 0) {
            articles.slice(0, articlesLimit.value)
          }
        }
      }
    }
    else if (utils.isDefined(form.filter.file) && excelWorkbook.value && form.mapping.sheetId && form.filter.type === 'excel') {
      if (refTemplateFileMappingEditor.value) {
        const worksheet = excelWorkbook.value.getWorksheet(form.mapping.sheetId)
        if (worksheet) {
          const articleNumbers: string[] = []
          const excelColIndexMap = refTemplateFileMappingEditor.value.excelColumns.reduce((result, value, index) => {
            result[value] = index
            return result
          }, {} as Record<string, number>)
          const mappingColIndices: Record<string, number[]> = {}
          refTemplateFileMappingEditor.value.selectedTemplateFileMapping.forEach((mapping) => {
            mappingColIndices[mapping.name] = []
            if (mapping.multiple && form.mapping[mapping.name] && Array.isArray(form.mapping[mapping.name])) {
              form.mapping[mapping.name].forEach((val) => {
                if (utils.isDefined(excelColIndexMap[val])) {
                  mappingColIndices[mapping.name].push(excelColIndexMap[val])
                }
              })
            }
            else if (utils.isDefined(excelColIndexMap[form.mapping[mapping.name]])) {
              mappingColIndices[mapping.name].push(excelColIndexMap[form.mapping[mapping.name]])
            }
          })
          if (mappingColIndices.articleNumber && mappingColIndices.articleNumber.length) {
            const excelData: Record<string, Record<string, string[]>> = {}
            const articleNumberColIndex = mappingColIndices.articleNumber[0] + 1
            worksheet.eachRow((row, rowNumber) => {
              const articleNumberCell = row.getCell(articleNumberColIndex)
              if (rowNumber > 1 && articleNumberCell && articleNumberCell.value) {
                const articleNumber = articleNumberCell.value.toString()
                articleNumbers.push(articleNumber)
                sortDataList.push(articleNumber)
                if (!utils.isDefined(excelData[articleNumber])) { excelData[articleNumber] = {} }
                for (const col in mappingColIndices) {
                  if (!utils.isDefined(excelData[articleNumber][col])) { excelData[articleNumber][col] = [] }
                  mappingColIndices[col].forEach((c) => {
                    const cell = row.getCell(c + 1)
                    if (cell && cell.value) {
                      excelData[articleNumber][col].push(cell.value.toString())
                    }
                  })
                }
              }
            })
            if (articleNumbers.length > 0) {
              listData = clone(articleNumbers)
              if (form.source === 'requests') {
                const listSet = new Set(listData)
                const queryCriterion: Array<[number, string]> = []
                for (let index = 0; index < listData.length; index++) {
                  queryCriterion.push([+userStore.activeCatalog.CatalogCode, listData[index]])
                }
                const sourceArticles = await appConfig.DB!.getArticlesByCatalogCodeArticleNumbers(userStore.activeCatalog!, queryCriterion, includeInactiveArticles, userStore.currentCustomer, userStore.currentCustomerSegmentations)
                modifyRequestValidArticleNumberList.push(...sourceArticles.map(article => article.ArticleNumber))
                const articleIdList = new Set(sourceArticles.map(article => article.Id))
                // fetch requests which is from another catalog and current catalog is part of the impacted catalogs list
                const requests = await appConfig.DB!.requests
                  .toArray()
                  .then(allRequests =>
                    allRequests
                      .filter(art =>
                        (art.CatalogCode === userStore.activeCatalog!.CatalogCode && (includeInactiveArticles || art.Status === 1))
                        || (art.RequestSource === requestConstants.requestSources.editArticle
                        && (art.CatalogCode === userStore.activeCatalog!.CatalogCode
                        || (art.ImpactedCatalogs && art.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)))),
                      ),
                  )
                for (const request of requests) {
                  if (request.IsCreateArticleRequest === 1) {
                    if (request.Content) {
                      const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB)
                      if (listSet.has(requestArticle.ArticleNumber)) {
                        articles.push(requestArticle)
                      }
                    }
                  }
                  else {
                    if (request.Content && articleIdList.has(request.SourceArticleId!)) {
                      requestsToModify.push(request)
                    }
                  }
                }
              }
              else {
                while (articles.length < userStore.activeCatalog.Config.ExportTemplateArticlesLimit && articleNumbers.length !== 0) {
                  const CurrentData = await getArticlesValidDBData(articleNumbers.splice(0, userStore.activeCatalog.Config.ExportTemplateArticlesLimit), true, sortedCatalogCode, isConsiderAllSeasonsData, includeInactiveArticles)
                  articles.push(...CurrentData.splice(0, userStore.activeCatalog.Config.ExportTemplateArticlesLimit))
                }
              }
            }
            result.excelData = excelData
          }
        }
      }
      else if (refExcelExportFileMappingEditor.value) {
        const worksheet = excelWorkbook.value.getWorksheet(form.mapping.sheetId)
        if (worksheet) {
          const articleNumbers: string[] = []
          const excelColIndexMap = refExcelExportFileMappingEditor.value.excelColumns.reduce((result, value, index) => {
            result[value] = index
            return result
          }, {} as Record<string, number>)
          const mappingColIndices: Record<string, number[]> = {}
          refExcelExportFileMappingEditor.value.exportMapping.forEach((mapping) => {
            mappingColIndices[mapping.name] = []
            if (utils.isDefined(excelColIndexMap[form.mapping[mapping.name]])) {
              mappingColIndices[mapping.name].push(excelColIndexMap[form.mapping[mapping.name]])
            }
          })
          if (mappingColIndices.articleNumber && mappingColIndices.articleNumber.length) {
            const excelData: Record<string, Record<string, string[]>> = {}
            const articleNumberColIndex = mappingColIndices.articleNumber[0] + 1
            worksheet.eachRow((row, rowNumber) => {
              const articleNumberCell = row.getCell(articleNumberColIndex)
              if (rowNumber > 1 && articleNumberCell && articleNumberCell.value) {
                const articleNumber = articleNumberCell.value.toString()
                articleNumbers.push(articleNumber)
                sortDataList.push(articleNumber)
                if (!utils.isDefined(excelData[articleNumber])) { excelData[articleNumber] = {} }
                for (const col in mappingColIndices) {
                  if (!utils.isDefined(excelData[articleNumber][col])) { excelData[articleNumber][col] = [] }
                  mappingColIndices[col].forEach((c) => {
                    const cell = row.getCell(c + 1)
                    if (cell && cell.value) {
                      excelData[articleNumber][col].push(cell.value.toString())
                    }
                  })
                }
              }
            })
            if (articleNumbers.length > 0) {
              listData = clone(articleNumbers)
              while (articles.length < userStore.activeCatalog.Config.ExportTemplateArticlesLimit && articleNumbers.length !== 0) {
                const CurrentData = await getArticlesValidDBData(articleNumbers.splice(0, userStore.activeCatalog.Config.ExportTemplateArticlesLimit), true, sortedCatalogCode, isConsiderAllSeasonsData, includeInactiveArticles)
                articles.push(...CurrentData.splice(0, userStore.activeCatalog.Config.ExportTemplateArticlesLimit))
              }
            }
            result.excelData = excelData
          }
        }
      }
    }
    const catalogCodeWiseArticle = new Map()
    listData = listData.filter(item => item !== '')
    validModels = clone(listData)
    articles.forEach((article) => {
      if (listData.length) {
        if (form.filter.type !== 'modelList') {
          listData = listData.filter(item => item !== article.ArticleNumber)
        }
        else {
          listData = listData.filter(item => item !== article.ModelNumber)
        }
      }

      if (!catalogCodeWiseArticle.has(article.CatalogCode)) {
        catalogCodeWiseArticle.set(article.CatalogCode, [article])
      }
      else {
        catalogCodeWiseArticle.get(article.CatalogCode).push(article)
      }
    })
    if (form.source === 'requests') {
      modifyRequestValidArticleNumberList.forEach((number) => {
        if (listData.length) {
          if (form.filter.type !== 'modelList') {
            listData = listData.filter(item => item !== number)
          }
          else {
            listData = listData.filter(item => item !== number)
            validModels = validModels.filter(item => item === number)
          }
        }
      })
    }
    const myArticlesData: MyArticle[] = []
    for (let index = 0; index < sortedCatalogCode.length; index++) {
      const catalogArticles = catalogCodeWiseArticle.get(sortedCatalogCode[index])
      if (catalogArticles && catalogArticles.length) {
        const catalog = userStore.linkedCatalogDetails[sortedCatalogCode[index]] ? userStore.linkedCatalogDetails[sortedCatalogCode[index]] : userStore.activeCatalog
        const catalogMyArticles = await appConfig.DB!.buildMyArticles(catalogArticles, catalog, userStore.linkedCatalogDetails, userStore.myAttributes, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        if (catalogMyArticles && catalogMyArticles.length) {
          myArticlesData.push(...catalogMyArticles)
        }
      }
    }
    validModels = validModels.filter(model => !listData.includes(model))
    form.reviewData.validDataCount = form.filter.type !== 'modelList' || form.source === 'orderForm' ? myArticlesData.length : validModels.length
    if (form.source === 'requests') {
      form.reviewData.validDataCount += form.filter.type === 'full' || form.filter.type === 'fav' ? requestsToModify.length : modifyRequestValidArticleNumberList.length
    }
    result.articles = myArticlesData
    form.reviewData.invalidData = listData
    if (form.filter.type === 'list' || form.filter.type === 'modelList' || form.filter.type === 'excel') {
      result.articles = sortArticlesSequence(result.articles, sortDataList)
    }
    else {
      result.articles = utils.sortMyArticles(result.articles)
    }
  }
  // do not call getArticlesAssets when no valid articles
  if (form.format !== 'excel' && result.articles.length) {
    const res = await utils.tryAsync(getArticlesAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, result.articles.map(s => s.ArticleNumber), true))
    if (res.success && res.result.data && res.result.data.length) {
      const assets = res.result.data
      if (assets && assets.length > 0) {
        const assetMap = {}
        assets.forEach((asset) => {
          if (!assetMap[asset.ImageSet] || assetMap[asset.ImageSet].SortOrder > asset.SortOrder) {
            assetMap[asset.ImageSet] = asset
          }
        })
        if (userStore.activeCatalog && userStore.activeCatalog.Config.NewestImageAssetKeyList.length) {
          const sortedAssetsByDate = sortBy(assets, a => a.UpdatedOn).reverse()
          const newestImageAssetKeySet = new Set(userStore.activeCatalog.Config.NewestImageAssetKeyList.map(x => x.toLowerCase()))
          const matchAssetByConfig = sortedAssetsByDate.find(a => utils.isDefined(a.Key) && newestImageAssetKeySet.has(a.Key.toLowerCase()))
          if (matchAssetByConfig) {
            assetMap[matchAssetByConfig.ImageSet] = matchAssetByConfig
          }
        }
        result.articles.forEach((article) => {
          if (assetMap[article.ArticleNumber]) {
            article._Assets.push(assetMap[article.ArticleNumber])
          }
        })
      }
    }
  }
  exportData.articles = result.articles
  exportData.excelData = result.excelData
  exportData.requestData = requestsToModify
}
function sortArticlesSequence(articles, sortDataList) {
  if (sortDataList.length && utils.isValidStringValue(form.filter.text) && form.filter.type === 'list') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ArticleNumber) - sortDataList.indexOf(b.ArticleNumber))
      || a.ArticleNumber > b.ArticleNumber || -(a.ArticleNumber < b.ArticleNumber))
  }
  else if (sortDataList.length && utils.isValidStringValue(form.filter.text) && form.filter.type === 'modelList') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ModelNumber) - sortDataList.indexOf(b.ModelNumber))
      || a.ModelNumber > b.ModelNumber || -(a.ModelNumber < b.ModelNumber))
  }
  else if (sortDataList.length && utils.isDefined(form.filter.file) && excelWorkbook.value && form.mapping.sheetId && form.filter.type === 'excel') {
    articles.sort((a, b) =>
      (sortDataList.indexOf(a.ArticleNumber) - sortDataList.indexOf(b.ArticleNumber))
      || a.ArticleNumber > b.ArticleNumber || -(a.ArticleNumber < b.ArticleNumber))
  }
  return articles
}
// creates lines for order form TODO: this should load the image for article instead of getIndexedExportingArticlesImage base on include images toggle status
function getDataToExportForOrderForm(articles) {
  const orderFormLines: any[] = []
  let invalidArticles: string[] = []
  let deliveryDateCriteriaMatched = false
  let checkDeliveryDate = false
  // check for 1 delivery date matching with the criteria if yes that means all will match, can copy for others
  if (isCustomerRequiredDateValidatorAvailable.value) {
    let customerRequiredDateValidation = {} as { relation: string, numberOfDays: number }
    if (userStore.activeCatalog!.CustomerRequiredDateValidation != null) { // CustomerRequiredDateValidation will not be null in this function
      try {
        customerRequiredDateValidation = JSON.parse(userStore.activeCatalog!.CustomerRequiredDateValidation)
      }
      catch (error) {
        console.warn(error)
      }
    }
    for (let i = 0; i < articles.length; i++) {
      for (let j = 0; j < articles[i]._DeliveryDate.length; j++) {
        checkDeliveryDate = true
        const catalogCustomerRequiredDate = new Date(userStore.activeCatalog!._IndexedCatalogCRD[articles[i]._DeliveryDate[j].CrdId].CustomerRequiredDate)
        if (utils.validateCondition(catalogCustomerRequiredDate.getTime(), catalogCustomerRequiredDate.getTime(), customerRequiredDateValidation.relation)) {
          deliveryDateCriteriaMatched = true
          break
        }
        if (checkDeliveryDate) {
          break
        }
      }
    }
  }
  // const isStockApply = userStore.activeCatalog!.IsStockApply && userStore.currentCustomer !== null
  articles.forEach((article) => {
    const articleClone = clone(article)
    // once stock module is implemented -if stock apply need to add the code for adding stock data in excel order form
    // const sortedCRDEntries = Object.entries(article._DeliveryDates).sort((a, b) => {
    //   const dateA = new Date(a[1].CustomerRequiredDate)
    //   const dateB = new Date(b[1].CustomerRequiredDate)
    //   return  dateA - dateB
    // })
    // if size curve is enabled then instread of delivery dates need to find the valid sze curve and add the data for the same
    if (article._DeliveryDates.length) {
      let atleastOneDeliveryIsActive = false
      article._DeliveryDates.forEach((deliveryDate) => {
        if (deliveryDate.Status && userStore.activeCatalog!._IndexedCatalogCRD[deliveryDate.CrdId] && userStore.activeCatalog!._IndexedCatalogCRD[deliveryDate.CrdId].Status) {
          atleastOneDeliveryIsActive = true
          const sortedArticleSizes: Size[] = utils.sort(article._Sizes, ['SortOrder', 'SizeIndex'])
          if (sortedArticleSizes.length === 0) {
            invalidArticles.push(articleClone.ArticleNumber)
          }
          let atleastOneSizeIsActive = false
          sortedArticleSizes.forEach((size) => {
            if (size.Status) {
              atleastOneSizeIsActive = true
              articleClone.CustomerRequiredDate = userStore.activeCatalog!._IndexedCatalogCRD[deliveryDate.CrdId].CustomerRequiredDate
              articleClone.CustomerRequiredDateDescription = userStore.activeCatalog!._IndexedCatalogCRD[deliveryDate.CrdId].Description
              articleClone.CustomerRequiredDateAvailabilityFrom = deliveryDate.AvailabilityFrom
              articleClone.CustomerRequiredDateAvailabilityTo = deliveryDate.AvailabilityTo
              articleClone.SizeName = size.SizeName
              articleClone.SizeIndex = size.SizeIndex
              if (isCustomerRequiredDateValidatorAvailable.value) {
                articleClone.DeliveryDate = deliveryDateCriteriaMatched ? userStore.activeCatalog!._IndexedCatalogCRD[deliveryDate.CrdId].CustomerRequiredDate : null
              }
              orderFormLines.push(clone(articleClone))
            }
          })
          if (!atleastOneSizeIsActive) {
            invalidArticles.push(articleClone.ArticleNumber)
          }
        }
      })
      if (!atleastOneDeliveryIsActive) {
        invalidArticles.push(articleClone.ArticleNumber)
      }
    }
    else {
      invalidArticles.push(articleClone.ArticleNumber)
    }
  })
  invalidArticles = uniq(invalidArticles)
  form.reviewData.validDataCount = form.reviewData.validDataCount - invalidArticles.length
  form.reviewData.invalidData = form.reviewData.invalidData.concat(invalidArticles)
  return orderFormLines
}
async function onFinish() {
  const exportDefaultImagePath = userStore.activeCatalog!.Config.ExportDefaultImagePath
  errorMessage.value = ''
  if (form.format === 'excel') {
    loading.value = true
    if (form.source === 'articles') {
      let exportableArticles = exportData.articles
      if (form.options.favoriteTagsInSeparateRow) {
        const exportableArticlesCopy = clone(exportableArticles)
        const favoriteArticles: Record<string, MyArticle[]> = {}
        for (const article of exportableArticlesCopy) {
          if (article._FavoriteTags && article._FavoriteTags.length > 0) {
            const favoriteTags = [...article._FavoriteTags]
            favoriteTags.forEach((tag) => {
              if (!favoriteArticles.hasOwnProperty(tag.Tag)) {
                favoriteArticles[tag.Tag] = []
              }
              const articleCopy = clone(article)
              articleCopy._FavoriteTags = [tag]
              favoriteArticles[tag.Tag].push(articleCopy)
            })
          }
          else {
            if (!favoriteArticles.hasOwnProperty('')) {
              favoriteArticles[''] = []
            }
            favoriteArticles[''].push(article)
          }
        }
        exportableArticles = Object.values(favoriteArticles).flat()
      }
      await generateExcel(exportableArticles)
    }
    else if (form.source === 'orderForm') {
      await generateOrderFormExcel(orderLines.value, form)
    }
    else if (form.source === 'requests') {
      await generateRequestExcel(exportData.articles, exportData.requestData)
    }
    else if (form.source === 'merchBoard') {
      const slideDataMap = {}
      const promises: Promise<any>[] = []
      const articlesIdList: number[] = []
      const failedSlides: string[] = []
      if (userStore.activeCatalog) {
        for (let i = 0; i < form.filter.merchSelectedNodes.length; i++) {
          const currentNode = form.filter.merchSelectedNodes[i]
          promises.push(getSlideDetails(userStore.activeCatalog.CatalogCode, currentNode.key).then((response) => {
            try {
              let parsedSlideData = JSON.parse(response.data.Content)
              if (typeof parsedSlideData === 'string') { // handle the case where user does not take the latest update and create slide and upload it in server which make the double stringify content
                parsedSlideData = JSON.parse(parsedSlideData)
              }
              parsedSlideData.slidesData.forEach((parsedObject) => {
                let groupObjects: any[] = []
                if (parsedObject.type === merchConstants.objectTypes.group) {
                  groupObjects = getGroupObjects(parsedObject, groupObjects)
                  groupObjects.forEach((object) => {
                    if (utils.isDefined(object.articleId)) {
                      articlesIdList.push(object.articleId)
                    }
                  })
                }
                else {
                  if (utils.isDefined(parsedObject.articleId)) {
                    articlesIdList.push(parsedObject.articleId)
                  }
                }
              })
              slideDataMap[response.data.SlideId] = parsedSlideData.slidesData
            }
            catch (error) {
              console.warn('warn invalidSlide content')
            }
          }).catch(() => {
            failedSlides.push(currentNode.label)
          }))
        }
        await Promise.all(promises).then(async () => {
          if (Object.keys(slideDataMap).length) {
            if (failedSlides.length) {
              errorMessage.value = t('exportDialog.messages.failedSomeSlides', { failedSlides: failedSlides.join('') })
            }
            let exportableArticles = await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articlesIdList, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)

            if (form.options.favoriteTagsInSeparateRow) {
              const exportableArticlesCopy = clone(exportableArticles)
              const favoriteArticles: Record<string, MyArticle[]> = {}
              for (const article of exportableArticlesCopy) {
                if (article._FavoriteTags && article._FavoriteTags.length > 0) {
                  const favoriteTags = [...article._FavoriteTags]
                  favoriteTags.forEach((tag) => {
                    if (!favoriteArticles.hasOwnProperty(tag.Tag)) {
                      favoriteArticles[tag.Tag] = []
                    }
                    const articleCopy = clone(article)
                    articleCopy._FavoriteTags = [tag]
                    favoriteArticles[tag.Tag].push(articleCopy)
                  })
                }
                else {
                  if (!favoriteArticles.hasOwnProperty('')) {
                    favoriteArticles[''] = []
                  }
                  favoriteArticles[''].push(article)
                }
              }
              exportableArticles = Object.values(favoriteArticles).flat()
            }
            await generateExcel(exportableArticles)
          }
          else {
            if (failedSlides.length) {
              errorMessage.value = t('exportDialog.messages.allSlidesFailed')
            }
          }
        })
      }
    }
    loading.value = false
    visible.value = false
  }
  else if (form.format === 'ppt') {
    loading.value = true
    const exportRequest: ExportRequest = {
      props: {
        layout: 'CUSTOM',
        custom_layout: {
          width: frameSizes.wide.width / 96,
          height: frameSizes.wide.height / 96,
          name: 'custom layout based on first frame',
        },
      },
      slides: [],
    }
    if (form.source === 'whiteboardFrame' && dbDoc && form.filter.whiteboardFrames.length > 0) {
      const objsMap = new Map<string, any>()
      for (const key in dbDoc.data) {
        if (key !== 'version') {
          objsMap.set(key, dbDoc.data[key])
        }
      }
      const firstFrame = objsMap.get(form.filter.whiteboardFrames[0])
      exportRequest.props.custom_layout!.width = firstFrame.width / 96
      exportRequest.props.custom_layout!.height = firstFrame.height / 96
      const articleObjectsCatalogCodeToIdMap = {}
      const modelObjectsCatalogCodeToModelNumberMap = {}
      for (const frameId of form.filter.whiteboardFrames) {
        const frame = objsMap.get(frameId)
        if (frame && frame.children && frame.children.length > 0) {
          for (const objectId of frame.children) {
            const obj = objsMap.get(objectId)
            if (obj && obj.type) {
              if (obj.type === whiteboardConstants.objectTypes.articleDetails || obj.type === whiteboardConstants.objectTypes.articleImage) {
                if (!articleObjectsCatalogCodeToIdMap[obj.catalogCode]) {
                  articleObjectsCatalogCodeToIdMap[obj.catalogCode] = [obj.articleId]
                }
                else {
                  articleObjectsCatalogCodeToIdMap[obj.catalogCode].push(obj.articleId)
                }
              }
              else if (obj.type === whiteboardConstants.objectTypes.modelDetails || obj.type === whiteboardConstants.objectTypes.modelImage) {
                if (!modelObjectsCatalogCodeToModelNumberMap[obj.catalogCode]) {
                  modelObjectsCatalogCodeToModelNumberMap[obj.catalogCode] = [obj.modelNumber]
                }
                else {
                  modelObjectsCatalogCodeToModelNumberMap[obj.catalogCode].push(obj.modelNumber)
                }
              }
            }
          }
        }
      }
      const catalogArticleMap = {}
      const catalogModelNumberMap = {}
      const articleNumbersForAssets: Array<string> = []
      const assetMap = {}
      const modelNumberToArticleNumberMap = {}
      for (const catalogCode in articleObjectsCatalogCodeToIdMap) {
        catalogArticleMap[catalogCode] = {}
        const catalogDetails = userStore.linkedCatalogDetails[catalogCode] ? userStore.linkedCatalogDetails[catalogCode] : userStore.activeCatalog
        const myArticles = await appConfig.DB!.getMyArticles(catalogDetails!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articleObjectsCatalogCodeToIdMap[catalogCode], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        myArticles.forEach((article) => {
          articleNumbersForAssets.push(article.ArticleNumber)
          catalogArticleMap[catalogCode][article.Id] = article
        })
      }
      for (const catalogCode in modelObjectsCatalogCodeToModelNumberMap) {
        catalogModelNumberMap[catalogCode] = {}
        const catalogDetails = userStore.linkedCatalogDetails[catalogCode] ? userStore.linkedCatalogDetails[catalogCode] : userStore.activeCatalog
        const myArticles = await appConfig.DB!.getFirstActiveMyArticleByModelNumber(modelObjectsCatalogCodeToModelNumberMap[catalogCode], catalogDetails!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
        myArticles.forEach((article) => {
          articleNumbersForAssets.push(article.ArticleNumber)
          modelNumberToArticleNumberMap[article.ModelNumber] = article.ArticleNumber
          catalogModelNumberMap[catalogCode][article.ModelNumber] = article
        })
      }
      // do not call getArticlesAssets when no valid articles
      if (articleNumbersForAssets.length) {
        const res = await utils.tryAsync(getArticlesAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, articleNumbersForAssets))
        if (res.success && res.result.data && res.result.data.length) {
          const assets = res.result.data
          if (assets && assets.length > 0) {
            assets.forEach((asset) => {
              if (!assetMap[asset.ImageSet]) {
                assetMap[asset.ImageSet] = []
              }
              assetMap[asset.ImageSet].push(asset)
            })
          }
        }
      }
      for (const frameId of form.filter.whiteboardFrames) {
        const frame = objsMap.get(frameId)
        if (frame && frame.children && frame.children.length > 0) {
          const slide: Slide = { props: { masterName: frame.name }, background: { color: tinycolor(frame.fill).toHex() }, objects: [] }
          const objects: (ImageObject | ShapeObject | TextObject)[] = []
          for (const objectId of frame.children) {
            await toPptJson(objsMap.get(objectId), frame, exportDefaultImagePath, userStore, objects, catalogArticleMap, catalogModelNumberMap, assetMap, modelNumberToArticleNumberMap)
            if (objects.length) {
              slide.objects! = objects
            }
          }
          exportRequest.slides.push(slide)
        }
      }
    }
    if (form.source === 'merchBoard' && form.filter.merchSelectedNodes.length > 0) {
      const slideDataMap = {}
      const promises: Promise<any>[] = []
      const articlesIdList: number[] = []
      const catalogArticleMap = {}
      const articleNumbersForAssets: Array<string> = []
      const assetMap = {}
      const failedSlides: string[] = []
      if (userStore.activeCatalog) {
        for (let i = 0; i < form.filter.merchSelectedNodes.length; i++) {
          const currentNode = form.filter.merchSelectedNodes[i]
          promises.push(getSlideDetails(userStore.activeCatalog.CatalogCode, currentNode.key).then((response) => {
            try {
              let parsedSlideData = JSON.parse(response.data.Content)
              if (typeof parsedSlideData === 'string') { // handle the case where user does not take the latest update and create slide and upload it in server which make the double stringify content
                parsedSlideData = JSON.parse(parsedSlideData)
              }
              parsedSlideData.slidesData.forEach((parsedObject) => {
                let groupObjects: any[] = []
                if (parsedObject.type === merchConstants.objectTypes.group) {
                  groupObjects = getGroupObjects(parsedObject, groupObjects)
                  groupObjects.forEach((object) => {
                    if (utils.isDefined(object.articleId)) {
                      articlesIdList.push(object.articleId)
                    }
                  })
                }
                else {
                  if (utils.isDefined(parsedObject.articleId)) {
                    articlesIdList.push(parsedObject.articleId)
                  }
                }
              })
              slideDataMap[response.data.SlideId] = parsedSlideData.slidesData
            }
            catch (error) {
              console.warn('warn invalidSlide content')
            }
          }).catch(() => {
            failedSlides.push(currentNode.label)
          }))
        }
        await Promise.all(promises).then(async () => {
          if (Object.keys(slideDataMap).length) {
            if (failedSlides.length) {
              errorMessage.value = t('exportDialog.messages.failedSomeSlides', { failedSlides: failedSlides.join('') })
            }
            const myArticles = await appConfig.DB!.getMyArticles(userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, articlesIdList, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
            myArticles.forEach((article) => {
              articleNumbersForAssets.push(article.ArticleNumber)
              catalogArticleMap[article.Id] = article
            })
            if (articleNumbersForAssets.length) {
              const res = await utils.tryAsync(getArticlesAssets(userStore.activeCatalog!.DuneContext, userStore.activeCatalog!.ContextKey, articleNumbersForAssets))
              if (res.success && res.result.data && res.result.data.length) {
                const assets = res.result.data
                if (assets && assets.length > 0) {
                  assets.forEach((asset) => {
                    if (!assetMap[asset.ImageSet]) {
                      assetMap[asset.ImageSet] = []
                    }
                    assetMap[asset.ImageSet].push(asset)
                  })
                }
              }
            }
            exportRequest.props.custom_layout!.width = merchConstants.slideSizePPTDimensions[form.filter.slideSize].width
            exportRequest.props.custom_layout!.height = merchConstants.slideSizePPTDimensions[form.filter.slideSize].height
            for (let i = 0; i < form.filter.merchSelectedNodes.length; i++) {
              const currentNode = form.filter.merchSelectedNodes[i]
              if (slideDataMap[currentNode.key]) {
                const slide: Slide = { props: { masterName: currentNode.label }, objects: [] }
                const merchObjects = slideDataMap[currentNode.key]
                const objects: (ImageObject | ShapeObject | TextObject)[] = []
                for (let i = 0; i < merchObjects.length; i++) {
                  await merchtoPptJson(merchObjects[i], exportDefaultImagePath, userStore, catalogArticleMap, assetMap, objects)
                }
                if (objects.length) {
                  slide.objects! = objects
                }
                exportRequest.slides.push(slide)
              }
            }
          }
          else {
            if (failedSlides.length) {
              errorMessage.value = t('exportDialog.messages.allSlidesFailed')
            }
          }
        })
      }
    }
    else if (templates[form.templateId] && userStore.activeCatalog && userStore.myAttributes) {
      const templateData = exportData
      if (templateData.articles.length > 0) {
        const startPosition = { x: 0, y: 0 }
        const objects = await templates[form.templateId].generate(userStore.activeCatalog, userStore.linkedCatalogDetails, templateData.articles, form.options, userStore.myAttributes, userStore.currentUsername, startPosition, templateData.excelData, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true)
        exportRequest.props.custom_layout!.width = frameSizes[form.options.frameSize].width / 96
        exportRequest.props.custom_layout!.height = frameSizes[form.options.frameSize].height / 96

        let framePPTObjects: (ImageObject | ShapeObject | TextObject)[] = []
        for (let index = 0; index < objects.length; index++) {
          const object = objects[index]
          if (object instanceof WbFrame) {
            const slide: Slide = { props: { masterName: object.name }, objects: [] }
            slide.objects = framePPTObjects
            framePPTObjects = []
            exportRequest.slides.push(slide)
          }
          else {
            await toPptJson(object, { top: 0, left: 0 }, exportDefaultImagePath, userStore, framePPTObjects)
          }
        }
      }
    }
    // it was exporting empty slides when no valid articles present
    if (exportRequest.slides.length) {
      generate(exportRequest, 'ppt')
        .then(async (res) => {
          if (userStore.activeCatalog && res.data) {
            const job = new Job({
              Id: res.data.Id,
              CatalogCode: userStore.activeCatalog.CatalogCode,
              Type: 'exportPPT',
              Status: 'new',
              DownloadUrl: '',
              UserId: userStore.userProfile.Id,
              CreatedDate: new Date(),
            })
            await appConfig.DB!.jobs.put(job)
            const message = t('exportDialog.messages.success')
            notificationStore.addNotification({ message, type: 'Success' })
            visible.value = false
          }
        })
        .catch((e) => {
          console.error(e)
          errorMessage.value = t('general.unexpectedError')
        })
        .finally(() => loading.value = false)
    }
    else {
      loading.value = false
      visible.value = false
    }
  }
  else if (form.format === 'pdf') {
    loading.value = true
    if (pdfTemplates[form.templateId] && userStore.activeCatalog) {
      const templateData = exportData
      if (templateData.articles.length > 0) {
        const pdfJson = await pdfTemplates[form.templateId].generate(userStore.activeCatalog, templateData.articles, form.options, userStore.priceGroups.retail)
        generate(pdfJson, 'pdf')
          .then(async (res) => {
            if (userStore.activeCatalog && res.data) {
              const job = new Job({
                Id: res.data.Id,
                CatalogCode: userStore.activeCatalog.CatalogCode,
                Type: 'exportPDF',
                Status: 'new',
                DownloadUrl: '',
                UserId: userStore.userProfile.Id,
                CreatedDate: new Date(),
              })
              await appConfig.DB!.jobs.put(job)
              const message = t('exportDialog.messages.success')
              notificationStore.addNotification({ message, type: 'Success' })
              visible.value = false
            }
          })
          .catch((e) => {
            console.error(e)
            errorMessage.value = t('general.unexpectedError')
          })
          .finally(() => loading.value = false)
      }
      else {
        loading.value = false
        visible.value = false
      }
    }
    else {
      loading.value = false
      visible.value = false
    }
  }
}
async function generateOrderFormExcel(orderLines, form) {
  const CELL_MERGE_COUNT = 7
  const worksheetPageSetup = {
    margins: {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    },
  }
  const worksheetProperties = {
    // there is an issue with that so have to do it manually one by one
    defaultRowHeight: 14.25,
    defaultColWidth: 21,
  }
  totalImages.value = 0
  downloadedImageCount.value = 0
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('OrderForm', {
    pageSetup: worksheetPageSetup,
    properties: worksheetProperties,
  })
  const format = 'png'
  const columnsHeader: IcolumnHeader[] = []
  let colIndex = form.options.includeImages ? 2 : 1
  if (form.options.includeImages) {
    columnsHeader.push({ header: ['', '', 'Images'], key: 'Images', width: 16 })
  }
  const selectedColumns = form.options.selectedColumns
  const selectedLocations = form.options.selectedLocations
  for (let index = 0; index < selectedColumns.length; index++) {
    columnsHeader.push({ header: ['', '', selectedColumns[index].DisplayName], key: selectedColumns[index].SystemName })
  }
  for (let index = 0; index < selectedLocations.length; index++) {
    columnsHeader.push({ header: [`${selectedLocations[index].customerName} (${selectedLocations[index].customerNumber})`, `${selectedLocations[index].locationName} (${selectedLocations[index].locationCode})`, ''], key: selectedLocations[index].customerLocationKey })
  }
  // add columns header to sheet
  worksheet.columns = columnsHeader

  // style header
  const headerFont = { bold: true }
  worksheet.getRow(1).font = headerFont
  worksheet.getRow(2).font = headerFont
  worksheet.getRow(3).font = headerFont
  for (let index = 1; index <= worksheet.columns.length; index++) {
    worksheet.getCell(`${utils.numberToExcelColumnName(index)}3`).border = { bottom: { style: 'medium' } }
  }
  if (orderLines.length > 0 && userStore.activeCatalog) {
    let noImageId: number | null = null

    if (form.options.includeImages) {
      const res = await axios.get(`${userStore.activeCatalog!.Config.ExportDefaultImagePath}`, { responseType: 'arraybuffer' })
      const base64 = res.data ? utils.arrayBufferToBase64(res.data) : null
      if (base64) {
        noImageId = workbook.addImage({
          base64,
          extension: 'png',
        })
      }
    }
    let previousArticleId
    let previousArticleStartRowIndex = 3
    let newArticle = false
    let articleCounter = 0
    let repeatedArticleCounter = 0
    const imagePromises: Promise<any>[] = []
    const imageDimensions = { width: 100, height: 100 }
    const downloadImageIndices = new Map<string, number>()
    const commonImages = new Map<string, number>()
    let previousArticleCrd = ''
    for (let index = 1; index <= orderLines.length; index++) {
      let rowIndex = index + 3
      colIndex = form.options.includeImages ? 2 : 1
      const orderLine = orderLines[index - 1]
      if (form.options.includeImages) {
        if (previousArticleId !== orderLine.Id) {
          previousArticleId = orderLine.Id
          newArticle = true
          articleCounter++
        }
        repeatedArticleCounter++
        rowIndex = previousArticleStartRowIndex + repeatedArticleCounter
        if (newArticle) {
          const indexAfterImage = articleCounter !== 1 ? previousArticleStartRowIndex + CELL_MERGE_COUNT : 2
          if (rowIndex < indexAfterImage) {
            rowIndex = indexAfterImage
          }
          previousArticleStartRowIndex = rowIndex
          repeatedArticleCounter = 0
          newArticle = false
          worksheet.mergeCells(`A${rowIndex}:A${rowIndex + CELL_MERGE_COUNT - 1}`)

          if (!commonImages.has(orderLine.ArticleNumber)) {
            downloadImageIndices.set(orderLine.ArticleNumber, rowIndex)
            const params = new URLSearchParams()
            params.set('Context', userStore.activeCatalog.DuneContext)
            params.set('ContextKey', userStore.activeCatalog.ContextKey)
            params.set('ImageSet', orderLine.ArticleNumber)
            params.set('w', imageDimensions.width.toString())
            params.set('h', imageDimensions.height.toString())
            params.set('trim', 'true')
            params.set('f', format)
            if (userStore.activeCatalog.Config.NewestImageAssetKeyList.length) {
              params.set('Key', userStore.activeCatalog.Config.NewestImageAssetKeyList.toString())
            }
            imagePromises.push(imageDownloadLimit(() =>
              axios.get(`${appConfig.AssetsUrl}/assets/r?${params.toString()}`, { responseType: 'arraybuffer' })
                .then((response) => {
                  const base64 = response.data ? utils.arrayBufferToBase64(response.data) : null
                  if (base64) {
                    const imageId = workbook.addImage({
                      base64,
                      extension: format,
                    })

                    commonImages.set(orderLine.ArticleNumber, imageId)
                    const currentIndex = downloadImageIndices.get(orderLine.ArticleNumber)
                    if (currentIndex) {
                      worksheet.addImage(imageId, {
                        tl: { col: 0, row: currentIndex },
                        ext: { width: imageDimensions.width, height: imageDimensions.height },
                      })
                    }
                  }
                })
                .catch((e) => {
                  console.warn(e)
                  const currentIndex = downloadImageIndices.get(orderLine.ArticleNumber)
                  if (utils.isDefined(noImageId) && currentIndex) {
                    worksheet.addImage(noImageId, {
                      tl: { col: 0, row: currentIndex },
                      ext: { width: imageDimensions.width, height: imageDimensions.height },
                    })
                  }
                })
                .finally(() => downloadedImageCount.value++),
            ))
          }
          else {
            const imageId = commonImages.get(orderLine.ArticleNumber)
            if (utils.isDefined(imageId)) {
              worksheet.addImage(imageId, {
                tl: { col: colIndex, row: rowIndex },
                ext: { width: imageDimensions.width, height: imageDimensions.height },
              })
            }
          }
        }
      }
      let highlightRow = false
      if (previousArticleCrd !== orderLine.CustomerRequiredDateDescription + orderLine.Id) {
        highlightRow = true
        previousArticleCrd = orderLine.CustomerRequiredDateDescription + orderLine.Id
      }
      for (let c = 0; c < selectedColumns.length; c++, colIndex++) {
        const value = await utils.getAttributeTypeSpecificValue(selectedColumns[c], orderLine, appConfig.DB, userStore.activeCatalog, userStore.priceGroups)
        worksheet.getCell(`${utils.numberToExcelColumnName(colIndex)}${rowIndex}`).value = value?.toString()
        if (highlightRow) {
          worksheet.getCell(`${utils.numberToExcelColumnName(colIndex)}${rowIndex}`).style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ADD8E6' },
            },
          }
        }
      }
      for (let c = 0; c < selectedLocations.length; c++, colIndex++) {
        let segs: Segmentation[] = []
        const customerSegmentation: CustomerSegmentation | undefined = await appConfig.DB!.getCustomerSegmentations(userStore.activeCatalog!, selectedLocations[c].customerId, false)
        if (customerSegmentation && customerSegmentation.Segmentations && customerSegmentation.Segmentations.length) {
          segs = customerSegmentation.Segmentations.filter(seg => seg.Status === 1)
        }
        const isSegmented = appConfig.DB!.isArticleSegmented(
          orderLine._Segmentations,
          segs,
          userStore.activeCatalog!._IndexedCatalogSegmentation,
        )
        worksheet.getCell(`${utils.numberToExcelColumnName(colIndex)}${rowIndex}`).style = {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: isSegmented ? '00E2EFDA' : '00A6A6A6' },
          },
        }
      }
    }
    // TODO, temporary solution to set the heigh of each row
    for (let i = 2; i <= worksheet.rowCount; i++) {
      worksheet.getRow(i).height = 14.25
    }
    totalImages.value = downloadImageIndices.size
    await Promise.all(imagePromises)

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', 'orderForm.xlsx')
    link.click()
  }
}
async function generateExcel(articles: MyArticle[]) {
  totalImages.value = 0
  downloadedImageCount.value = 0
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Articles')
  const indexedCatalogPriceGroups = {}
  // all price groups
  userStore.activeCatalog!.CatalogPriceGroupList.forEach((priceGroup) => {
    indexedCatalogPriceGroups[priceGroup.Id] = priceGroup
  })
  if (userStore.activeCatalog && userStore.myAttributes) {
    const format = 'png'
    const columns: string[] = form.options.checkedFields
    const columnsDisplayName: string[] = []
    let noImageId: number | null = null
    if (form.options.includeImages) {
      columns.unshift('Image')
    }
    for (const column of columns) {
      columnsDisplayName.push(userStore.myAttributes[column] ? userStore.myAttributes[column].DisplayName : indexedCatalogPriceGroups[column] ? indexedCatalogPriceGroups[column].Description : column)
    }
    worksheet.addRow(columnsDisplayName)

    if (form.options.includeImages) {
      const imageColumn = worksheet.getColumn('A')
      imageColumn.width = 15

      const res = await axios.get(`${userStore.activeCatalog.Config.ExportDefaultImagePath}`, { responseType: 'arraybuffer' })
      const base64 = res.data ? utils.arrayBufferToBase64(res.data) : null
      if (base64) {
        noImageId = workbook.addImage({
          base64,
          extension: 'png',
        })
      }
    }

    const imagePromises: Promise<any>[] = []
    const imageDimensions = { width: 100, height: 100 }
    const downloadImageIndices = new Map<string, number>()
    const commonImages = new Map<string, number>()
    let rowIndex = 1
    for (const article of articles) {
      const rowData: any[] = []
      for (const column of columns) {
        if (userStore.myAttributes[column]) {
          rowData.push(await utils.getAttributeTypeSpecificValue(userStore.myAttributes[column], article, appConfig.DB, userStore.activeCatalog, userStore.priceGroups))
        }
        else if (indexedCatalogPriceGroups[column] && article._Prices && article._Prices[column] && article._Prices[column].Price) {
          rowData.push(utils.formatPrice(indexedCatalogPriceGroups[column], article._Prices[column].Price, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated))
        }
        else if (column === 'DropReason' && article[column]) {
          rowData.push(article[column])
        }
        else {
          rowData.push('')
        }
      }

      const addedRow = worksheet.addRow(rowData)
      if (form.options.includeImages) {
        addedRow.height = 100

        if (!commonImages.has(article.ArticleNumber)) {
          downloadImageIndices.set(article.ArticleNumber, rowIndex)
          const params = new URLSearchParams()
          params.set('Context', userStore.activeCatalog.DuneContext)
          params.set('ContextKey', userStore.activeCatalog.ContextKey)
          params.set('ImageSet', article.ArticleNumber)
          params.set('w', imageDimensions.width.toString())
          params.set('h', imageDimensions.height.toString())
          params.set('trim', 'true')
          params.set('f', format)
          if (userStore.activeCatalog.Config.NewestImageAssetKeyList.length) {
            params.set('Key', userStore.activeCatalog.Config.NewestImageAssetKeyList.toString())
          }
          imagePromises.push(imageDownloadLimit(() =>
            axios.get(`${appConfig.AssetsUrl}/assets/r?${params.toString()}`, { responseType: 'arraybuffer' })
              .then((response) => {
                const base64 = response.data ? utils.arrayBufferToBase64(response.data) : null
                if (base64) {
                  const imageId = workbook.addImage({
                    base64,
                    extension: format,
                  })

                  commonImages.set(article.ArticleNumber, imageId)
                  const currentIndex = downloadImageIndices.get(article.ArticleNumber)
                  if (currentIndex) {
                    worksheet.addImage(imageId, {
                      tl: { col: 0, row: currentIndex },
                      ext: { width: imageDimensions.width, height: imageDimensions.height },
                    })
                  }
                }
              })
              .catch((e) => {
                console.warn(e)
                const currentIndex = downloadImageIndices.get(article.ArticleNumber)
                if (utils.isDefined(noImageId) && currentIndex) {
                  worksheet.addImage(noImageId, {
                    tl: { col: 0, row: currentIndex },
                    ext: { width: imageDimensions.width, height: imageDimensions.height },
                  })
                }
              })
              .finally(() => downloadedImageCount.value++),
          ))
        }
        else {
          const imageId = commonImages.get(article.ArticleNumber)
          if (utils.isDefined(imageId)) {
            worksheet.addImage(imageId, {
              tl: { col: 0, row: rowIndex },
              ext: { width: imageDimensions.width, height: imageDimensions.height },
            })
          }
        }
      }
      rowIndex++
    }

    totalImages.value = downloadImageIndices.size
    await Promise.all(imagePromises)

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', 'articles.xlsx')
    link.click()
  }
}

async function generateRequestExcel(articles: MyArticle[], modifyArticleRequests: RequestModel[]) {
  const workbook = new ExcelJS.Workbook()
  const createArticleRequestWorksheet = workbook.addWorksheet('Create Article Requests')
  const modifyArticleRequestWorksheet = workbook.addWorksheet('Modify Article Requests')
  if (userStore.activeCatalog && userStore.myAttributes) {
    const translatedData = await utils.getModifyArticleRequestsTranslatedData(userStore.activeCatalog, userStore.myAttributes, modifyArticleRequests, userStore.sizeScales, userStore.articleStateList, appConfig.DB!)
    const createArticleRequestColumns: IKeyLabel<string>[] = [
      { key: 'Id', label: t('filter.requestId') },
      { key: 'ArticleNumber', label: t('filter.requestNumber') },
      { key: '_ConfirmArticleNumber', label: t('general.articleNumber') },
      { key: 'Status', label: t('general.status') },
      { key: '_RequestState', label: t('filter.requestStatus') },
      { key: '_RequestSource', label: t('filter.requestType') },
      { key: '_SourceModelNumber', label: t('requests.sourceModelNumber') },
      { key: '_Comment', label: 'Decision Comments' },
      { key: '_Reason', label: 'Rejection Reason' },
      { key: 'CreatedByUserName', label: 'Created By' },
      { key: 'CreatedDate', label: 'Created Date' },
      { key: 'UpdatedByUserName', label: 'Updated By' },
      { key: 'UpdatedDate', label: 'Updated Date' },
    ]
    const modifyArticleRequestColumns: IKeyLabel<string>[] = [
      { key: 'Id', label: t('filter.requestId') },
      { key: '_SourceArticleNumber', label: t('general.articleNumber') },
      { key: '_RequestSourceModelNumber', label: t('general.modelNumber') },
      { key: '_SourceModelName', label: t('general.modelName') },
      { key: '_impactedAttribute', label: t('requests.impactedAttribute') },
      { key: '_initialValue', label: t('requests.currentValue') },
      { key: '_requestedValue', label: t('requests.requestedValue') },
      { key: 'Status', label: t('general.status') },
      { key: '_RequestState', label: t('filter.requestStatus') },
      { key: 'RequestType', label: t('filter.requestType') },
      { key: 'Comment', label: 'Decision Comments' },
      { key: 'Reason', label: 'Rejection Reason' },
      { key: '_ImpactedSeasons', label: t('requestsTable.fields.impactedSeasons') },
      { key: 'CreatedByUserName', label: 'Created By' },
      { key: 'CreatedDate', label: 'Created Date' },
      { key: 'UpdatedByUserName', label: 'Updated By' },
      { key: 'UpdatedDate', label: 'Updated Date' },
    ]
    const isRequestAttribute = ['CreatedByUserName', 'CreatedDate', 'UpdatedByUserName', 'UpdatedDate']
    if (userStore.activeCatalog.RequestAttributeList && userStore.activeCatalog.RequestAttributeList.length) {
      userStore.activeCatalog.RequestAttributeList.forEach((attr) => {
        createArticleRequestColumns.push({ key: attr.AttributeSystemName, label: attr.AttributeDisplayName })
        modifyArticleRequestColumns.push({ key: attr.AttributeSystemName, label: attr.AttributeDisplayName })
      })
    }
    if (form.options.checkedFields && form.options.checkedFields.length) {
      form.options.checkedFields.forEach((field) => {
        createArticleRequestColumns.push({ key: field, label: userStore.myAttributes![field] ? userStore.myAttributes![field].DisplayName : field })
        modifyArticleRequestColumns.push({ key: field, label: userStore.myAttributes![field] ? userStore.myAttributes![field].DisplayName : field })
      })
    }

    createArticleRequestWorksheet.addRow(createArticleRequestColumns.map(c => c.label))
    modifyArticleRequestWorksheet.addRow(modifyArticleRequestColumns.map(c => c.label))
    for (const article of articles) {
      const rowData: any[] = []
      for (const column of createArticleRequestColumns) {
        if (userStore.myAttributes[column.key]) {
          rowData.push(await utils.getAttributeTypeSpecificValue(userStore.myAttributes[column.key], article, appConfig.DB, userStore.activeCatalog, userStore.priceGroups))
        }
        else if (article[column.key]) {
          rowData.push(article[column.key])
        }
        else {
          rowData.push('')
        }
      }
      createArticleRequestWorksheet.addRow(rowData)
    }
    for (const modifyRequest of translatedData) {
      const rowData: any[] = []
      for (const column of modifyArticleRequestColumns) {
        if (userStore.myAttributes[column.key]) {
          rowData.push(await utils.getAttributeTypeSpecificValue(userStore.myAttributes[column.key], isRequestAttribute.includes(column.key) ? modifyRequest : modifyRequest._article || modifyRequest, appConfig.DB, userStore.activeCatalog, userStore.priceGroups))
        }
        else if (modifyRequest[column.key]) {
          rowData.push(modifyRequest[column.key])
        }
        else {
          rowData.push('')
        }
      }
      modifyArticleRequestWorksheet.addRow(rowData)
    }
  }
  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', 'requests.xlsx')
  link.click()
}

function updateFormOptions(modelValue) {
  form.options = modelValue
}

watch(() => form.filter.type, (val) => {
  if (refTemplateOptionsEditorExport.value) {
    refTemplateOptionsEditorExport.value.disableOptions.frameTitle = val === 'excel'
    refTemplateOptionsEditorExport.value.reset()
  }
  if (refExcelExportFileMappingEditor.value) {
    refExcelExportFileMappingEditor.value.reset()
  }
})

defineExpose({
  showDialog,
})
</script>
