<template>
  <div class="overflow-hidden">
    <div ref="el" :style="height ? `height: ${height}` : ''">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import type { PanzoomObject } from '@panzoom/panzoom'
import Panzoom from '@panzoom/panzoom'

defineProps<{
  height?: string
}>()

defineExpose({ zoomIn, zoomOut, reset })

const el = ref<HTMLElement>()
let panzoom: PanzoomObject

function zoomIn() {
  if (panzoom) {
    panzoom.zoomIn()
  }
}

function zoomOut() {
  if (panzoom) {
    panzoom.zoomOut()
  }
}

function reset() {
  if (panzoom) {
    panzoom.reset()
  }
}

onMounted(() => {
  if (el.value) {
    panzoom = Panzoom(el.value)
    if (el.value.parentElement) {
      el.value.parentElement.addEventListener('wheel', panzoom.zoomWithWheel)
    }
  }
})

onUnmounted(() => {
  if (el.value && el.value.parentElement) {
    el.value.parentElement.removeEventListener('wheel', panzoom.zoomWithWheel)
  }
})
</script>
