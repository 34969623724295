<template>
  <div v-show="visible" class="flex flex-col w-full h-full">
    <!-- SUB HEADER -->
    <div class="flex-shrink-0 text-center">
      <!-- SUB TITLE -->
      <div class="text-base text-gray-600 mb-7">
        {{ t('modelCreate.subTitle.articleForm') }}
      </div>
      <!-- NOTES -->
      <div class="text-sm text-gray-600" :class="{ 'mb-8': headerNotes.length }">
        {{ headerNotes }}
      </div>
    </div>
    <tx-checkbox
      v-if="numberOfArticleToBeCreated > 1" v-model="setCommonValueForAllEditableAttributes" class="mb-3 ml-[106px] cursor-pointer"
      :indeterminate="setCommonValueForAllEditableAttributesIsIndeterminate" :label="t('modelCreate.allAttributesSameValue')"
      @change="onSetCommonValueForAllDynamicAttributesCheckChange"
    />
    <!-- BODY -->
    <div class="flex-grow mx-24 my-3 overflow-auto">
      <!-- HEADER ROW -->
      <div class="sticky top-0 z-20 flex items-start h-10 bg-white w-fit flex-nowrap">
        <!-- HEADER COLUMN -->
        <!-- TODO: added h-15 due to issue with tooltip that needs to be fixed -->
        <div
          v-for="attribute in visibleAttributes" :key="attribute.SystemName"
          class="w-44 h-15 flex flex-col shrink-0 items-baseline whitespace-nowrap text-ellipsis px-[10px] pb-[10px] m-0 first-of-type:sticky first-of-type:left-0 first-of-type:bg-white"
        >
          <form-attribute-label
            class="w-full font-bold" :attribute="attribute"
            :checked="headerCheckBoxModel[attribute.SystemName]"
            :required="attribute.IsRequired"
            :show-checkbox="!attribute.ReadOnly && numberOfArticleToBeCreated > 1"
            :show-external-change-management-info="true" :show-update-seasonless-attribute-warning="false"
            @check-changed="onSetCommonValueForCurrentDynamicAttributesCheckChange(attribute, $event)"
          />
        </div>
        <template v-if="isAddRequest">
          <div
            v-for="(attribute, index) in requestAttributes" :key="attribute.SystemName"
            class="w-44 h-15 flex flex-col shrink-0 items-baseline whitespace-nowrap text-ellipsis px-[10px] pb-[10px] m-0"
            :class="{ 'border-l-4': index === 0 }"
          >
            <form-attribute-label
              class="w-full font-semibold" :attribute="attribute"
              :checked="headerCheckBoxModel[attribute.SystemName]"
              :required="attribute.IsRequired"
              :show-checkbox="!attribute.ReadOnly && numberOfArticleToBeCreated > 1"
              :show-external-change-management-info="true" :show-update-seasonless-attribute-warning="false"
              @check-changed="onSetCommonValueForCurrentDynamicAttributesCheckChange(attribute, $event)"
            />
          </div>
        </template>
      </div>
      <!-- DATA ROW -->
      <div v-for="rowKey in articleFormModelKeys" :key="rowKey" class="flex h-14 w-fit flex-nowrap">
        <!-- DATA COLUMN -->
        <div
          v-for="attribute in visibleAttributes" :key="attribute.SystemName"
          class="w-44 flex flex-col shrink-0 items-baseline justify-center overflow-hidden whitespace-nowrap text-ellipsis p-[10px] m-0 first-of-type:sticky first-of-type:left-0 first-of-type:z-10 first-of-type:bg-white"
        >
          <!-- ARTICLE PLACEHOLDER -->
          <div v-if="attribute.SystemName === 'Article'">
            {{ articleFormModel[rowKey][attribute.SystemName] }}
          </div>
          <!-- PERIOD -->
          <tx-select
            v-else-if="attribute.SystemName === 'Period'" v-model="articleFormModel[rowKey].Period"
            :data="getRetailWindowByCriteria()" display-prop="Period" value-prop="Period" :sort-list="false"
            :required="attribute.IsRequired" clearable filterable :errors="v$[rowKey].Period?.$errors"
            :disabled="attribute.ReadOnly || (disabledColumnsBasedOnSetCommonValue[rowKey] && disabledColumnsBasedOnSetCommonValue[rowKey][attribute.SystemName]) || (disabledCell[rowKey] && disabledCell[rowKey][attribute.SystemName])"
            @blur="v$[rowKey].Period?.$touch" @change="onShippingAttributeChange(rowKey, attribute, undefined)"
          />
          <!-- RETAIL INTRO MONTH -->
          <tx-select
            v-else-if="attribute.SystemName === 'RetailIntroMonth'" v-model="articleFormModel[rowKey].RetailIntroMonth"
            :data="getRetailWindowByCriteria(articleFormModel[rowKey].Period, undefined)" display-prop="RetailIntroMonth" value-prop="RetailIntroMonth"
            :required="attribute.IsRequired" clearable filterable :sort-list="false" :errors="v$[rowKey].RetailIntroMonth?.$errors"
            :disabled="attribute.ReadOnly || (disabledColumnsBasedOnSetCommonValue[rowKey] && disabledColumnsBasedOnSetCommonValue[rowKey][attribute.SystemName]) || (disabledCell[rowKey] && disabledCell[rowKey][attribute.SystemName])"
            @blur="v$[rowKey].RetailIntroMonth?.$touch" @change="onShippingAttributeChange(rowKey, attribute, undefined)"
          />
          <!-- RETAIL EXIST MONTH -->
          <tx-select
            v-else-if="attribute.SystemName === 'RetailExitMonth'" v-model="articleFormModel[rowKey].RetailExitMonth"
            :data="getRetailWindowByCriteria(articleFormModel[rowKey].Period, articleFormModel[rowKey].RetailIntroMonth)" display-prop="RetailExitMonth" value-key="Id"
            :required="attribute.IsRequired" clearable filterable :sort-list="false" :errors="v$[rowKey].RetailExitMonth?.$errors"
            :disabled="attribute.ReadOnly || (disabledColumnsBasedOnSetCommonValue[rowKey] && disabledColumnsBasedOnSetCommonValue[rowKey][attribute.SystemName]) || (disabledCell[rowKey] && disabledCell[rowKey][attribute.SystemName])"
            @blur="v$[rowKey].RetailExitMonth?.$touch" @change="onShippingAttributeChange(rowKey, attribute, $event)"
          />
          <!-- SHIPMENT START DATE -->
          <div v-else-if="attribute.SystemName === 'ShipmentStartDate'">
            <div>{{ utils.formatDateUTC(articleFormModel[rowKey]?.ShipmentStartDate) }}</div>
          </div>
          <!-- SHIPMENT END DATE -->
          <div v-else-if="attribute.SystemName === 'ShipmentEndDate'">
            <div>{{ utils.formatDateUTC(articleFormModel[rowKey]?.ShipmentEndDate) }}</div>
          </div>
          <attribute-editor
            v-else v-model="articleFormModel[rowKey][attribute.SystemName]" :attribute="attribute" :articles="createType === 'copyModel' || createType === 'newModel' ? [copyModelArticleObject] : targetedArticles"
            :pre-fill-color="false"
            :disabled="attribute.ReadOnly || (disabledColumnsBasedOnSetCommonValue[rowKey] && disabledColumnsBasedOnSetCommonValue[rowKey][attribute.SystemName]) || (disabledCell[rowKey] && disabledCell[rowKey][attribute.SystemName])"
            :show-label="false" :required="attribute.IsRequired" :form="articleFormModel[rowKey]" clearable
            @blur="v$[rowKey][attribute.SystemName].$touch" @change="setCurrentAttributeValueForAllRecords(attribute)"
          />
        </div>
        <template v-if="isAddRequest">
          <div
            v-for="(attribute, index) in requestAttributes" :key="attribute.SystemName"
            class="w-44 flex flex-col shrink-0 items-baseline justify-center overflow-hidden whitespace-nowrap text-ellipsis p-[10px] m-0 first-of-type:sticky first-of-type:left-0 first-of-type:z-10 first-of-type:bg-white"
            :class="{ 'border-l-4': index === 0 }"
          >
            <attribute-editor
              v-model="articleFormModel[rowKey][attribute.SystemName]" :attribute="attribute" :articles="createType === 'copyModel' || createType === 'newModel' ? [copyModelArticleObject] : targetedArticles"
              :pre-fill-color="false"
              :disabled="attribute.ReadOnly || (disabledColumnsBasedOnSetCommonValue[rowKey] && disabledColumnsBasedOnSetCommonValue[rowKey][attribute.SystemName]) || (disabledCell[rowKey] && disabledCell[rowKey][attribute.SystemName])"
              :show-label="false" :required="attribute.IsRequired" :form="articleFormModel[rowKey]" clearable
              @blur="v$[rowKey][attribute.SystemName].$touch" @change="setCurrentAttributeValueForAllRecords(attribute)"
            />
          </div>
        </template>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="flex flex-row justify-end flex-shrink-0 mx-24 mb-16 flex-nowrap">
      <tx-button type="cancel" :text="t('general.cancel')" @click="onCancel" />
      <tx-button class="ml-5 capitalize" type="confirm" :text="nextButtonText" :disabled="v$.$invalid" @click="nextStep" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core'
import { isEmpty, uniqBy } from 'lodash-es'
import { computed, reactive, ref, toRefs, watch } from 'vue'
import { helpers, minValue, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import type Article from '@/models/article'
import FormAttributeLabel from '@/shared/components/FormAttributeLabel.vue'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import type MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import utils from '@/services/utils'
import { appConstants, requestConstants } from '@/models/constants'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useUserStore } from '@/store/userData'
import { AttributeType } from '@/models/catalogAttribute'

interface IFormItem {
  Article: string
  ArticleId?: number
  ArticleNumber?: string
  ArticleName?: string
  IsShippingWindowUpdatable?: number
  ModelId?: number
  ModelNumber?: string
  Period: string
  RetailExitMonth: CatalogShipmentWindowRange | undefined
  RetailIntroMonth: string
  ShipmentEndDate?: string
  ShipmentStartDate?: string
  ShipmentWindowRangeId?: number | null
  _isPlaceholder: boolean
  key: string
  AllowRetailWindowUpdateOnChild: boolean
  [key: string]: any
}

const props = defineProps<{
  createType: StyleCreateType
  articlesToCarryover: Array<MyArticle | Article>
  modelFormModel: Record<string, any>
  periodsFormModel: Record<string, string>
  retailWindowCriteriaAttributeValue: any | -999 // it will hold the value for a seasonless model level attribute
  sortedShipmentWindowRange: CatalogShipmentWindowRange[]
  targetedArticles: MyArticle[]
  requestArticles?: MyArticle[]
  visible: boolean
  visibleAttributes: IMyAttribute[]
  requestAttributes: IMyAttribute[]
  modelCopies: number
  isAddRequest: boolean
}>()

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'next', payload: Record<string, any>): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage } = useErrorMessage()
const { prefillAttributeValue, updateCriteriaAttributeAllowedValues, updateLookupAttributeValues } = useArticleFormHelper()

const headerNotes = ref('')
const articleFormModel = reactive<Record<string, IFormItem>>({})
const headerCheckBoxModel = reactive<Record<string, boolean>>({})
let formInitialized = false
const numberOfArticleToBeCreated = ref(0)
const setCommonValueForAllEditableAttributes = ref(false)
const setCommonValueForAllEditableAttributesIsIndeterminate = ref(false)
const disabledColumnsBasedOnSetCommonValue = reactive<Record<string, Record<string, boolean>>>({})
const disabledCell = reactive<Record<string, Record<string, boolean>>>({})
const refVisibleAttributes = ref(props.visibleAttributes)
const refRequestAttributes = ref(props.requestAttributes)

// COMPUTED
const articleFormModelKeys = computed(() => Object.keys(articleFormModel).sort((key1, key2) => articleFormModel[key2]._isPlaceholder ? 1 : articleFormModel[key1]._isPlaceholder ? -1 : 0))
const copyModelArticleObject = computed(() => {
  return props.modelFormModel as Article
})
const validations = computed(() => {
  const result: Record<string, any> = {}
  const requireFields: Record<string, any> = {}

  refVisibleAttributes.value.forEach((attribute) => {
    requireFields[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      requireFields[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
    if (attribute.AttributeSource === '_Prices') {
      requireFields[attribute.SystemName].minValue = helpers.withMessage(t('validations.nonZero', { property: attribute.DisplayName }), minValue(attribute.minValue! || 1))
    }
  })
  if (props.isAddRequest) {
    refRequestAttributes.value.forEach((attribute) => {
      requireFields[attribute.SystemName] = {}
      if (attribute.IsRequired) {
        requireFields[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
      }
      if (attribute.AttributeSource === '_Prices') {
        requireFields[attribute.SystemName].minValue = helpers.withMessage(t('validations.nonZero', { property: attribute.DisplayName }), minValue(attribute.minValue! || 1))
      }
    })
  }
  for (const rowKey in articleFormModel) {
    result[rowKey] = requireFields
  }
  return result
})

const nextButtonText = computed(() => t((props.isAddRequest ? 'modelCreate.addXRequests' : 'modelCreate.addXArticles'), { count: numberOfArticleToBeCreated.value }, numberOfArticleToBeCreated.value))
// COMPUTED-ENDS

// WATCHERS
watch(() => props.visibleAttributes, (val) => {
  refVisibleAttributes.value = val
})

watch(() => props.requestAttributes, (val) => {
  refRequestAttributes.value = val
})

// if user change style create type (eg from copy model to carryover model) or periods quantities or to articlesToCarryover form, make formInitialized false to the form will be reinitialized
watch(() => [props.createType, toRefs(props.periodsFormModel), toRefs(props.articlesToCarryover)], () => {
  formInitialized = false
})

watch(() => props.visible, (visible) => {
  if (visible && !formInitialized) {
    initForm()
    formInitialized = true
  }
})
// WATCHERS-ENDS

// FUNCTIONS
function initForm() {
  // reset articleFormModel
  utils.resetReactiveObject(articleFormModel)
  // INIT column checkboxes initial value
  refVisibleAttributes.value.forEach((visibleAttribute) => {
    if (!visibleAttribute.ReadOnly) {
      headerCheckBoxModel[visibleAttribute.SystemName] = !!headerCheckBoxModel[visibleAttribute.SystemName]
    }
  })

  numberOfArticleToBeCreated.value = 0
  const referenceArticleToCopySeasonlessData = props.targetedArticles[0]
  let referenceArticleToCopySeasonalDataForUnassortedArticles: MyArticle[] | Article[] = []
  let referenceArticleToCopySeasonalData: MyArticle | undefined
  if (props.createType !== 'newModel') { // based on code prefilling form for all apart new model, as discussed with Amardeep we gonna keep based on latest code on T1S
    referenceArticleToCopySeasonalDataForUnassortedArticles = utils.sort(props.articlesToCarryover, 'UpdatedDate', false)
    referenceArticleToCopySeasonalData = utils.sort(props.targetedArticles, 'UpdatedDate', false)[0]
  }

  // take the latest article among selected articles for individual model when carryover articles, and in case of carryover model take the latest from selected un assorted articles if no unassorted articles selected consider context article
  // ARTICLES TO CARRYOVER
  props.articlesToCarryover.forEach((unassortedArticle) => {
    const rowKey = unassortedArticle.CatalogArticleId
    articleFormModel[rowKey] = {} as IFormItem
    articleFormModel[rowKey]._isPlaceholder = false
    articleFormModel[rowKey].key = unassortedArticle.ArticleNumber
    articleFormModel[rowKey].Article = unassortedArticle.ArticleNumber
    articleFormModel[rowKey].ModelId = unassortedArticle.ModelId
    articleFormModel[rowKey].ArticleId = unassortedArticle.Id
    articleFormModel[rowKey].ArticleNumber = unassortedArticle.ArticleNumber
    articleFormModel[rowKey].ArticleName = unassortedArticle.ArticleName
    articleFormModel[rowKey].ColorId = unassortedArticle.ColorId
    articleFormModel[rowKey].ColorName = unassortedArticle.ColorName
    if (userStore.activeCatalog?.IsShippingWindowApply) {
      // pre fill with first shipment window sorted by period then sort by the retail intro month and exit month, props.retailWindowCriteriaAttributeValue should be equal to unassortedArticle[userStore.activeCatalog!.RetailWindowAttribute]
      const criteriaMatchedShippingWindowRanges = props.sortedShipmentWindowRange.filter(shipmentWindow => props.retailWindowCriteriaAttributeValue === -999
        || utils.haveEqualStringValue(shipmentWindow.CriteriaAttributeValue, props.retailWindowCriteriaAttributeValue))
      if (criteriaMatchedShippingWindowRanges.length) {
        // based on logic from T1S, period is not taken from unassorted article and instead taking from first shipmentWindow sorted by period
        let periodMatchedShippingWindowRanges = [] as Array<CatalogShipmentWindowRange>
        // if period is defined on article then use that, otherwise fallback on default
        // if article has period
        let periodValue = ''
        if (unassortedArticle.Period != null && unassortedArticle.Period.toString().trim().length) {
          periodValue = unassortedArticle.Period
          periodMatchedShippingWindowRanges = criteriaMatchedShippingWindowRanges.filter(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.Period, unassortedArticle.Period))
        }
        if (!periodMatchedShippingWindowRanges.length) { // default logic
          periodValue = criteriaMatchedShippingWindowRanges[0].Period
          periodMatchedShippingWindowRanges = criteriaMatchedShippingWindowRanges.filter(shipmentWindow => utils.haveEqualStringValue(shipmentWindow.Period, criteriaMatchedShippingWindowRanges[0].Period))
        }
        periodMatchedShippingWindowRanges.sort((a, b) => {
          if (a.RetailIntroMonth !== b.RetailIntroMonth) {
            const aRetailIntroMonth = a.RetailIntroMonth.toString().toLowerCase().trim().substring(0, 3)
            const bretailIntroMonth = b.RetailIntroMonth.toString().toLowerCase().trim().substring(0, 3)
            return appConstants.threeCharMonths.indexOf(aRetailIntroMonth) - appConstants.threeCharMonths.indexOf(bretailIntroMonth)
          }
          else {
            const aRetailExitMonth = a.RetailExitMonth.toString().toLowerCase().trim().substring(0, 3)
            const bretailExitMonth = b.RetailExitMonth.toString().toLowerCase().trim().substring(0, 3)
            return appConstants.threeCharMonths.indexOf(aRetailExitMonth) - appConstants.threeCharMonths.indexOf(bretailExitMonth)
          }
        })
        let defaultRetailWindow = periodMatchedShippingWindowRanges.length ? periodMatchedShippingWindowRanges[0] : undefined
        if (userStore.activeCatalog!.Config.RetailWindowDefaultValueCriteria && periodMatchedShippingWindowRanges.length) {
          // Set default value based on RetailWindowDefaultValueCriteria
          const defaultRetailConfigurations = userStore.activeCatalog!.Config.RetailWindowDefaultValueCriteria.defaultRetailConfigurations
          if (defaultRetailConfigurations) {
            // find the articleLifecycle, if article have SeasonalSequence use that else use the catalog SeasonalSequence
            const articleSeasonalSequence = unassortedArticle.SeasonalSequence ? unassortedArticle.SeasonalSequence : userStore.activeCatalog!.SeasonalSequence
            const articleLifeCycle = articleSeasonalSequence < userStore.activeCatalog!.SeasonalSequence ? 'carryover' : 'new'

            const retailWindowsMatchingLifecycleAndPeriod = defaultRetailConfigurations.filter((defaultRetailWindowRange) => {
              const windowRange = defaultRetailWindowRange as { Period?: string[], ArticleLifecycle?: string[] }
              return windowRange.Period?.map(p => p.toLowerCase()).includes(periodValue.toLowerCase())
                && (!windowRange.ArticleLifecycle || windowRange.ArticleLifecycle?.map(al => al.toLowerCase()).includes(articleLifeCycle))
            })

            // Loop through retailWindowsMatchingLifecycleAndPeriod and check if any key is an attribute
            for (const retailWindow of retailWindowsMatchingLifecycleAndPeriod) {
              let matchFound = false
              Object.keys(retailWindow).forEach((key) => {
                if (!matchFound && key !== 'ArticleLifecycle' && key !== 'Period' && key !== 'RetailIntroMonth' && key !== 'RetailExitMonth') {
                  // attribute
                  if (unassortedArticle[key] && retailWindow[key]?.includes(unassortedArticle[key])) {
                    const defaultRetailWindowValue = periodMatchedShippingWindowRanges.find((rw) => {
                      return rw.RetailIntroMonth === retailWindow.RetailIntroMonth && rw.RetailExitMonth === retailWindow.RetailExitMonth
                    })
                    if (defaultRetailWindowValue) {
                      defaultRetailWindow = defaultRetailWindowValue
                      matchFound = true
                    }
                  }
                }
              })
              if (matchFound) {
                break
              }
            }
          }
        }

        if (defaultRetailWindow) {
          articleFormModel[rowKey].Period = defaultRetailWindow.Period
          articleFormModel[rowKey].RetailIntroMonth = defaultRetailWindow.RetailIntroMonth
          articleFormModel[rowKey].RetailExitMonth = defaultRetailWindow
          articleFormModel[rowKey].ShipmentStartDate = utils.formatDateUTC(defaultRetailWindow.ShipmentStartDate)
          articleFormModel[rowKey].ShipmentEndDate = utils.formatDateUTC(defaultRetailWindow.ShipmentEndDate)
          articleFormModel[rowKey].ShipmentWindowRangeId = defaultRetailWindow.Id
        }
      }
    }

    // copy seasonless dynamic attributes and prices
    disabledCell[rowKey] = { ColorId: true }
    refVisibleAttributes.value.forEach((visibleAttribute) => {
      // copy seasonless attribute value from targeted article
      if (!visibleAttribute.IsStatic && visibleAttribute.IsSeasonless) {
        // do not prepopulate catalog Model Attribute and Article Attribute (Lincesee Model Number & Licensee Material Number)
        const catalogModelAttribute = userStore.activeCatalog?.ModelAttribute// its a dynamic property
        const catalogArticleAttribute = userStore.activeCatalog?.ArticleAttribute
        if (visibleAttribute.SystemName !== catalogModelAttribute && visibleAttribute.SystemName !== catalogArticleAttribute) {
          articleFormModel[rowKey][visibleAttribute.SystemName] = prefillAttributeValue(articleFormModel[rowKey], (props.createType === 'carryoverArticle' || props.createType === 'carryoverModel' ? unassortedArticle[visibleAttribute.SystemName] : referenceArticleToCopySeasonlessData[visibleAttribute.SystemName]), visibleAttribute)
        }
      }
      else if (visibleAttribute.AttributeSource === '_Prices') {
        articleFormModel[rowKey][visibleAttribute.SystemName] = unassortedArticle._Prices[visibleAttribute.SystemName]?.Price || null
      }

      // disable seasonless article level attributes for articles to be carried over
      if (visibleAttribute.IsSeasonless) {
        if (!disabledCell.hasOwnProperty(rowKey)) {
          disabledCell[rowKey] = {}
        }
        disabledCell[rowKey][visibleAttribute.SystemName] = true
      }
    })

    numberOfArticleToBeCreated.value++
  })

  // PLACEHOLDERS
  let placeholderCounter = 1
  for (const period in props.periodsFormModel) {
    const periodQuantity = Number.parseInt(props.periodsFormModel[period]) || 0
    numberOfArticleToBeCreated.value += periodQuantity

    // find all the shipping window that matches the attribute criteria value and current period
    let periodMatchedShippingWindowRanges: CatalogShipmentWindowRange[] = []
    if (userStore.activeCatalog?.IsShippingWindowApply) {
      // props.modelFormModel[userStore.activeCatalog!.RetailWindowAttribute] should be equal to props.retailWindowCriteriaAttributeValue
      periodMatchedShippingWindowRanges = props.sortedShipmentWindowRange.filter(shipmentWindow =>
        (props.retailWindowCriteriaAttributeValue === -999 || utils.haveEqualStringValue(shipmentWindow.CriteriaAttributeValue, props.retailWindowCriteriaAttributeValue))
        && (utils.haveEqualStringValue(shipmentWindow.Period, period)))

      periodMatchedShippingWindowRanges.sort((a, b) => {
        if (a.RetailIntroMonth !== b.RetailIntroMonth) {
          const aRetailIntroMonth = a.RetailIntroMonth.toString().toLowerCase().trim().substring(0, 3)
          const bretailIntroMonth = b.RetailIntroMonth.toString().toLowerCase().trim().substring(0, 3)
          return appConstants.threeCharMonths.indexOf(aRetailIntroMonth) - appConstants.threeCharMonths.indexOf(bretailIntroMonth)
        }
        else {
          const aRetailExitMonth = a.RetailExitMonth.toString().toLowerCase().trim().substring(0, 3)
          const bretailExitMonth = b.RetailExitMonth.toString().toLowerCase().trim().substring(0, 3)
          return appConstants.threeCharMonths.indexOf(aRetailExitMonth) - appConstants.threeCharMonths.indexOf(bretailExitMonth)
        }
      })
    }
    let defaultRetailWindow = periodMatchedShippingWindowRanges.length ? periodMatchedShippingWindowRanges[0] : undefined

    if (userStore.activeCatalog!.Config.RetailWindowDefaultValueCriteria && periodMatchedShippingWindowRanges.length) {
      // Set default value based on RetailWindowDefaultValueCriteria
      const defaultRetailConfigurations = userStore.activeCatalog!.Config.RetailWindowDefaultValueCriteria.defaultRetailConfigurations
      if (defaultRetailConfigurations) {
        const retailWindowsMatchingLifecycleAndPeriod = defaultRetailConfigurations.filter((defaultRetailWindowRange) => {
          return defaultRetailWindowRange.Period?.map(p => p.toLowerCase()).includes(period.toLowerCase())
            && (!defaultRetailWindowRange.ArticleLifecycle || defaultRetailWindowRange.ArticleLifecycle?.map(al => al.toLowerCase()).includes('new'))
        })

        // Loop through retailWindowsMatchingLifecycleAndPeriod and check if any key is an attribute
        for (const retailWindow of retailWindowsMatchingLifecycleAndPeriod) {
          let matchFound = false
          Object.keys(retailWindow).forEach((key) => {
            if (!matchFound && key !== 'ArticleLifecycle' && key !== 'Period' && key !== 'RetailIntroMonth' && key !== 'RetailExitMonth') {
              // attribute
              const formData = props.targetedArticles.length ? props.targetedArticles[0] : !isEmpty(props.modelFormModel) ? props.modelFormModel : {}
              if (!isEmpty(formData) && formData[key] && retailWindow[key]?.includes(formData[key])) {
                const defaultRetailWindowValue = periodMatchedShippingWindowRanges.find((rw) => {
                  return rw.RetailIntroMonth === retailWindow.RetailIntroMonth && rw.RetailExitMonth === retailWindow.RetailExitMonth
                })
                if (defaultRetailWindowValue) {
                  defaultRetailWindow = defaultRetailWindowValue
                  matchFound = true
                }
              }
            }
          })
          if (matchFound) {
            break
          }
        }
      }
    }

    for (let i = 0; i < periodQuantity; i++) {
      const rowKey = `_article${placeholderCounter}`
      articleFormModel[rowKey] = {} as IFormItem
      articleFormModel[rowKey]._isPlaceholder = true
      articleFormModel[rowKey].key = utils.randomString(10)
      articleFormModel[rowKey].Article = `${t('general.placeholder')} ${placeholderCounter}`
      // if shipping window apply
      if (userStore.activeCatalog?.IsShippingWindowApply && defaultRetailWindow) {
        articleFormModel[rowKey].Period = defaultRetailWindow.Period
        articleFormModel[rowKey].RetailIntroMonth = defaultRetailWindow.RetailIntroMonth
        articleFormModel[rowKey].RetailExitMonth = defaultRetailWindow
        articleFormModel[rowKey].ShipmentStartDate = utils.formatDateUTC(defaultRetailWindow.ShipmentStartDate)
        articleFormModel[rowKey].ShipmentEndDate = utils.formatDateUTC(defaultRetailWindow.ShipmentEndDate)
        articleFormModel[rowKey].ShipmentWindowRangeId = defaultRetailWindow.Id
      }
      articleFormModel[rowKey].AllowRetailWindowUpdateOnChild = true // set AllowRetailWindowUpdateOnChild to true, for article to carry over it will be taken from the selected article
      articleFormModel[rowKey].ModelId = props.createType !== 'newModel' && props.targetedArticles.length ? props.targetedArticles[0].ModelId : undefined
      articleFormModel[rowKey].ArticleId = undefined
      articleFormModel[rowKey].ArticleNumber = undefined
      placeholderCounter++
    }
  }

  if (props.requestArticles && props.requestArticles.length) {
    props.requestArticles.forEach((requestArticle) => {
      if (requestArticle._RequestSource === requestConstants.requestSources.carryover) {
        const rowKey = requestArticle.CatalogArticleId
        articleFormModel[rowKey] = {} as IFormItem
        articleFormModel[rowKey]._isPlaceholder = false
        articleFormModel[rowKey].key = requestArticle.ArticleNumber
        articleFormModel[rowKey].Article = requestArticle.ArticleNumber
        articleFormModel[rowKey].ModelId = requestArticle.ModelId
        articleFormModel[rowKey].ArticleId = requestArticle.Id
        articleFormModel[rowKey].ArticleNumber = requestArticle.ArticleNumber
        articleFormModel[rowKey].ArticleName = requestArticle.ArticleName
        articleFormModel[rowKey].ColorId = requestArticle.ColorId
        articleFormModel[rowKey].ColorName = requestArticle.ColorName
        // if shipping window apply
        if (requestArticle.ShipmentWindowRangeId && userStore.activeCatalog?._IndexedShipmentWindowRange[requestArticle.ShipmentWindowRangeId]) {
          const retailWindow = userStore.activeCatalog._IndexedShipmentWindowRange[requestArticle.ShipmentWindowRangeId]
          articleFormModel[rowKey].Period = retailWindow.Period
          articleFormModel[rowKey].RetailIntroMonth = retailWindow.RetailIntroMonth
          articleFormModel[rowKey].RetailExitMonth = retailWindow
          articleFormModel[rowKey].ShipmentStartDate = utils.formatDateUTC(retailWindow.ShipmentStartDate)
          articleFormModel[rowKey].ShipmentEndDate = utils.formatDateUTC(retailWindow.ShipmentEndDate)
          articleFormModel[rowKey].ShipmentWindowRangeId = retailWindow.Id
        }
        articleFormModel[rowKey].AllowRetailWindowUpdateOnChild = true
        disabledCell[rowKey] = { ColorId: true }
        refVisibleAttributes.value.forEach((visibleAttribute) => {
          if (!visibleAttribute.IsStatic && requestArticle[visibleAttribute.SystemName]) {
            articleFormModel[rowKey][visibleAttribute.SystemName] = requestArticle[visibleAttribute.SystemName]
          }
          else if (visibleAttribute.AttributeSource === '_Prices') {
            articleFormModel[rowKey][visibleAttribute.SystemName] = requestArticle._Prices[visibleAttribute.SystemName]?.Price || null
          }
          else if (visibleAttribute.AttributeType === AttributeType.Bool) {
            articleFormModel[rowKey][visibleAttribute.SystemName] = false
          }
          // disable seasonless article level attributes for articles to be carried over
          if (visibleAttribute.IsSeasonless) {
            if (!disabledCell.hasOwnProperty(rowKey)) {
              disabledCell[rowKey] = {}
            }
            disabledCell[rowKey][visibleAttribute.SystemName] = true
          }
        })

        numberOfArticleToBeCreated.value++
      }
      else {
        numberOfArticleToBeCreated.value++
        const rowKey = `_article${placeholderCounter}`
        articleFormModel[rowKey] = {} as IFormItem
        articleFormModel[rowKey]._isPlaceholder = true
        articleFormModel[rowKey].key = utils.randomString(10)
        articleFormModel[rowKey].Article = `${t('general.placeholder')} ${placeholderCounter}`
        // if shipping window apply
        if (requestArticle.ShipmentWindowRangeId && userStore.activeCatalog?._IndexedShipmentWindowRange[requestArticle.ShipmentWindowRangeId]) {
          const retailWindow = userStore.activeCatalog._IndexedShipmentWindowRange[requestArticle.ShipmentWindowRangeId]
          articleFormModel[rowKey].Period = retailWindow.Period
          articleFormModel[rowKey].RetailIntroMonth = retailWindow.RetailIntroMonth
          articleFormModel[rowKey].RetailExitMonth = retailWindow
          articleFormModel[rowKey].ShipmentStartDate = utils.formatDateUTC(retailWindow.ShipmentStartDate)
          articleFormModel[rowKey].ShipmentEndDate = utils.formatDateUTC(retailWindow.ShipmentEndDate)
          articleFormModel[rowKey].ShipmentWindowRangeId = retailWindow.Id
        }
        articleFormModel[rowKey].AllowRetailWindowUpdateOnChild = true
        refVisibleAttributes.value.forEach((visibleAttribute) => {
          if (!visibleAttribute.IsStatic && requestArticle[visibleAttribute.SystemName]) {
            articleFormModel[rowKey][visibleAttribute.SystemName] = requestArticle[visibleAttribute.SystemName]
          }
          else if (visibleAttribute.AttributeType === AttributeType.Bool) {
            articleFormModel[rowKey][visibleAttribute.SystemName] = false
          }
        })
        placeholderCounter++
      }
    })
  }
  else {
    for (const rowKey in articleFormModel) {
      refVisibleAttributes.value.forEach((attribute) => {
        // set default value for non static attributes
        // do not prepopulate catalog Model Attribute and Article Attribute (Lincesee Model Number & Licensee Material Number)
        const catalogModelAttribute = userStore.activeCatalog?.ModelAttribute// its a dynamic property
        const catalogArticleAttribute = userStore.activeCatalog?.ArticleAttribute
        const prePopulate = !utils.isDefined(attribute.prePopulate) || !!attribute.prePopulate
        if (prePopulate && !attribute.IsStatic && attribute.SystemName !== catalogModelAttribute && attribute.SystemName !== catalogArticleAttribute && attribute.AttributeType !== AttributeType.ColorPalette) {
          let attributeValue: any

          if (articleFormModel[rowKey]._isPlaceholder && referenceArticleToCopySeasonalData != null) {
            if (attribute.AttributeSource !== '_Prices') {
              attributeValue = referenceArticleToCopySeasonalData[attribute.SystemName]
              if (attribute.AttributeType === AttributeType.Bool) {
                attributeValue = attributeValue && (attributeValue.toString().toLowerCase() === 'true' || attributeValue.toString().toLowerCase() === '1')
              }
            }
            else {
              attributeValue = referenceArticleToCopySeasonalData._Prices[attribute.SystemName]?.Price || null
            }
          }
          else if ((props.createType === 'carryoverArticle' || props.createType === 'carryoverModel') && !articleFormModel[rowKey]._isPlaceholder) { // this is used only for articles to carry over for carryover model action
            let refArticle = referenceArticleToCopySeasonalDataForUnassortedArticles[0]
            if (props.createType === 'carryoverArticle') {
              const modelId = articleFormModel[rowKey].ModelId
              const firstUpdatedArticleOfModel = referenceArticleToCopySeasonalDataForUnassortedArticles.find(article => article.ModelId === modelId)
              if (firstUpdatedArticleOfModel) {
                refArticle = firstUpdatedArticleOfModel
              }
            }
            if (refArticle) {
              if (attribute.AttributeSource !== '_Prices') {
                attributeValue = refArticle[attribute.SystemName]
              }
              else {
                attributeValue = refArticle._Prices[attribute.SystemName]?.Price || null
              }
            }
          }

          // if attribute value is undefined and there is value in attribute's DefaultValue prefill unless the field is disabled
          if (
            (attributeValue == null || !attributeValue.toString().length)
            && utils.isDefined(attribute.DefaultValue) && attribute.DefaultValue.toString().length && !attribute.ReadOnly
          ) {
            attributeValue = attribute.DefaultValue
          }

          prefillAttributeValue(articleFormModel[rowKey], attributeValue, attribute)
        }
        else if (attribute.AttributeType === AttributeType.Bool) {
          articleFormModel[rowKey][attribute.SystemName] = false
        }
      })

      const dataForValidation = Object.assign({}, props.targetedArticles && props.targetedArticles.length ? props.targetedArticles[0] : {}, articleFormModel[rowKey])
      updateCriteriaAttributeAllowedValues(refVisibleAttributes, articleFormModel[rowKey], dataForValidation)
      updateLookupAttributeValues(refVisibleAttributes, articleFormModel[rowKey], dataForValidation)
    }
  }

  if (props.modelCopies && props.modelCopies > 1) {
    numberOfArticleToBeCreated.value = numberOfArticleToBeCreated.value * props.modelCopies
  }
}

const v$ = useVuelidate(validations, articleFormModel)

function getRetailWindowByCriteria(period?: string | null, retailIntroMonth?: string | null) {
  const result = props.sortedShipmentWindowRange.filter(shipmentWindowRange =>
    (props.retailWindowCriteriaAttributeValue === -999 || utils.haveEqualStringValue(shipmentWindowRange.CriteriaAttributeValue, props.retailWindowCriteriaAttributeValue))
    && (period == null || utils.haveEqualStringValue(shipmentWindowRange.Period, period))
    && (retailIntroMonth == null || utils.haveEqualStringValue(shipmentWindowRange.RetailIntroMonth, retailIntroMonth)),
  )
  // when query for period, return the unique values
  if (period == null) { // get me dropdown values for period
    return uniqBy(result, 'Period')
  }
  else if (retailIntroMonth == null) { // get me dropdown values for retail intro month
    return uniqBy(result, 'RetailIntroMonth').sort((a, b) => {
      const aRetailIntroMonth = a.RetailIntroMonth.toString().toLowerCase().trim().substring(0, 3)
      const bRetailIntroMonth = b.RetailIntroMonth.toString().toLowerCase().trim().substring(0, 3)
      return appConstants.threeCharMonths.indexOf(aRetailIntroMonth) - appConstants.threeCharMonths.indexOf(bRetailIntroMonth)
    })
  }
  else { // get me dropdown values for retail exit month)
    return result.sort((a, b) => {
      const aRetailExitMonth = a.RetailExitMonth.toString().toLowerCase().trim().substring(0, 3)
      const bRetailExitMonth = b.RetailExitMonth.toString().toLowerCase().trim().substring(0, 3)
      return appConstants.threeCharMonths.indexOf(aRetailExitMonth) - appConstants.threeCharMonths.indexOf(bRetailExitMonth)
    })
  }
}

function onShippingAttributeChange(rowKey: string, attribute: IMyAttribute, shipmentWindow) {
  articleFormModel[rowKey].ShipmentStartDate = ''
  articleFormModel[rowKey].ShipmentEndDate = ''
  articleFormModel[rowKey].ShipmentWindowRangeId = undefined
  if (attribute.SystemName === appConstants.staticAttributes.Period.SystemName) {
    articleFormModel[rowKey].RetailIntroMonth = ''
    articleFormModel[rowKey].RetailExitMonth = undefined
  }
  else if (attribute.SystemName === appConstants.staticAttributes.RetailIntroMonth.SystemName) {
    articleFormModel[rowKey].RetailExitMonth = undefined
  }
  else if (attribute.SystemName === appConstants.staticAttributes.RetailExitMonth.SystemName && utils.isDefined(shipmentWindow)) {
    articleFormModel[rowKey].ShipmentStartDate = shipmentWindow.ShipmentStartDate
    articleFormModel[rowKey].ShipmentEndDate = shipmentWindow.ShipmentEndDate
    articleFormModel[rowKey].ShipmentWindowRangeId = shipmentWindow.Id
  }
  setCurrentAttributeValueForAllRecords(attribute)
}

function onCancel() {
  emit('cancel')
}

function onSetCommonValueForAllDynamicAttributesCheckChange(isChecked: TxBooleanish) {
  for (const attributeSystemName in refVisibleAttributes.value) {
    if (!refVisibleAttributes.value[attributeSystemName].ReadOnly) {
      const attribute = refVisibleAttributes.value[attributeSystemName]
      if (!attribute.ReadOnly) {
        headerCheckBoxModel[attribute.SystemName] = !!isChecked
      }
      setCurrentAttributeValueForAllRecords(attribute)
    }
  }
  for (const attributeSystemName in refRequestAttributes.value) {
    if (!refRequestAttributes.value[attributeSystemName].ReadOnly) {
      const attribute = refRequestAttributes.value[attributeSystemName]
      if (!attribute.ReadOnly) {
        headerCheckBoxModel[attribute.SystemName] = !!isChecked
      }
      setCurrentAttributeValueForAllRecords(attribute)
    }
  }
}

function onSetCommonValueForCurrentDynamicAttributesCheckChange(attribute: IMyAttribute, isChecked: boolean) {
  // there is some issue with checkbox which is not setting the model value
  headerCheckBoxModel[attribute.SystemName] = isChecked
  const editableAttributes: IMyAttribute[] = []
  editableAttributes.push(...refVisibleAttributes.value.filter(visibleAttribute => !visibleAttribute.ReadOnly))
  editableAttributes.push(...refRequestAttributes.value.filter(visibleAttribute => !visibleAttribute.ReadOnly))
  const firstAttributeCheckboxValue = headerCheckBoxModel[editableAttributes[0].SystemName]
  // set indeterminate for table check box to false
  setCommonValueForAllEditableAttributesIsIndeterminate.value = false
  for (let i = 1; i < editableAttributes.length; i++) {
    if (headerCheckBoxModel[editableAttributes[i].SystemName] !== firstAttributeCheckboxValue) {
      // if first checkbox value is not the same as any of the leading column check boxes, make table check value indeterminate
      setCommonValueForAllEditableAttributes.value = false
      setCommonValueForAllEditableAttributesIsIndeterminate.value = true
      break
    }
  }
  // if it is not indeterminate and if first one is checked (firstAttributeCheckboxValue is true) then set setCommonValueForAllEditableAttributes to true else set it to false
  if (!setCommonValueForAllEditableAttributesIsIndeterminate.value) {
    if (firstAttributeCheckboxValue) {
      setCommonValueForAllEditableAttributes.value = true
    }
    else {
      setCommonValueForAllEditableAttributes.value = false
    }
  }

  setCurrentAttributeValueForAllRecords(attribute)
}

function setCurrentAttributeValueForAllRecords(attribute: IMyAttribute) {
  const rowKyeList = articleFormModelKeys.value
  const firstArticleRecord = articleFormModel[rowKyeList[0]]
  const setCommonValue = headerCheckBoxModel[attribute.SystemName] || setCommonValueForAllEditableAttributes.value
  for (let i = 1; i < rowKyeList.length; i++) {
    // disable all rows except the first for current attribute
    if (!disabledColumnsBasedOnSetCommonValue.hasOwnProperty(rowKyeList[i])) {
      disabledColumnsBasedOnSetCommonValue[rowKyeList[i]] = {}
    }
    disabledColumnsBasedOnSetCommonValue[rowKyeList[i]][attribute.SystemName] = setCommonValue

    // set common values
    if (setCommonValue && !attribute.ReadOnly && !(disabledCell[rowKyeList[i]] && disabledCell[rowKyeList[i]][attribute.SystemName])) {
      articleFormModel[rowKyeList[i]][attribute.SystemName] = firstArticleRecord[attribute.SystemName]
      if (attribute.SystemName === appConstants.staticAttributes.RetailExitMonth.SystemName) {
        articleFormModel[rowKyeList[i]].ShipmentStartDate = firstArticleRecord.ShipmentStartDate
        articleFormModel[rowKyeList[i]].ShipmentEndDate = firstArticleRecord.ShipmentEndDate
        articleFormModel[rowKyeList[i]].ShipmentWindowRangeId = firstArticleRecord.ShipmentWindowRangeId
      }
    }
  }
}

async function nextStep() {
  if (!await v$.value.$validate()) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  if (props.createType === 'carryoverArticle' || props.createType === 'carryoverModel') {
    const seasonlessAttributes = refVisibleAttributes.value.filter(attribute => attribute.IsSeasonless)
    Object.keys(articleFormModel).forEach((key) => {
      if (!articleFormModel[key]._isPlaceholder) {
        seasonlessAttributes.forEach((attribute) => {
          delete articleFormModel[key][attribute.SystemName]
        })
      }
    })
  }
  emit('next', { modelFormModel: undefined, modelArticlesAndPeriodsFormModel: undefined, articleFormModel })
}
</script>
